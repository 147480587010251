<template>
	<div>
		<h2 class="text-center text-[24px] mb-8 mx-auto tablet:w-8/12 desktop:w-10/12 font-bold">
			{{ $t('subscribe.step.course.header') }}
		</h2>
		<div class="w-full mb-8">
			<div class="flex justify-center items-center flex-wrap phablet:flex-nowrap gap-4">
				<ImageChip
					v-for="instrument in filteredInstruments"
					:key="instrument.id"
					:instrument="instrument"
					:data-cy="`instrument-${instrument.id}`"
					:is-selected="isCategorySelected(instrument.planCategory)"
					:thumbnail="instrument.image"
					:is-highlighted="isHighlighted"
					class="w-full max-w-96"
					@select="selectPlanCategory(instrument.planCategory)"
				>
					{{ $t('instrumentCard.title', [localize(instrument.displayName)]) }}
				</ImageChip>
			</div>
		</div>
		<transition-group name="fade">
			<div
				v-if="selectedPlanCategory && !isCombo && allowsTrialOrder"
				key="stepSection"
				class="w-full mb-8"
			>
				<h3 class="font-bold text-center mb-8">
					{{ $t('subscribe.step.course.experienceLevel.title') }}
				</h3>
				<div class="flex justify-center flex-wrap mobile:flex-nowrap gap-4">
					<AppChip
						v-for="experienceLevel in experienceLevels"
						:key="experienceLevel.id"
						:is-selected="experienceLevel.id === selectedExperienceLevel.id"
						:data-cy="`select_experienceLevel_${experienceLevel.id}`"
						@click="selectExperienceLevel(experienceLevel.id)"
					>
						{{ localize(experienceLevel.name) }}
					</AppChip>
				</div>
			</div>
			<div
				key="stepFooter"
				class="mt-8 text-center"
			>
				<AppButton
					:is-disabled="!validateStepSelection"
					:is-loading="loading"
					data-cy="button_nextStep"
					class="mx-auto"
					@click="nextStep"
				>
					{{ $t('button.nextStep') }}
				</AppButton>
				<div class="mt-6">
					<div class="flex flex-col justify-center items-center phablet:flex-row divide-y phablet:divide-y-0 phablet:divide-x divide-solid divide-gray-300">
						<div class="py-1 px-4 phablet:text-right">
							<AppLink
								:link="{ name: 'redeem-coupon' }"
								class="text-gray-700 hover:text-primary-400"
							>
								{{ $t('button.coupon.redeem') }}
							</AppLink>
						</div>
						<div
							v-if="instruments.length > 1"
							class="py-1 px-4 phablet:text-left"
						>
							<AppLink
								:link="{ name: 'signup-comboplan' }"
								class="text-gray-700 hover:text-primary-400"
							>
								{{ $t('button.combo.try') }}
								<template v-if="highestComboSaving > 0">
									<br>
									<strong class="text-primary-400">{{ $t('generic.saveUpTo_brackets', [highestComboSaving]) }}</strong>
								</template>
							</AppLink>
							<TooltipWithIcon
								:tooltip="{
									content: $t(`tooltip.combopass${allowsTrialOrder ? '_trial' : ''}`),
									placement: 'right',
								}"
								data-cy="tooltip_combopass"
							/>
						</div>
					</div>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script lang="ts" setup>
import TooltipWithIcon from '@/components/TooltipWithIcon.vue';
import { ExperienceLevel, experienceLevels } from '@/models/ExperienceLevel';
import { SupportedLocale, localize } from '@/models/Language';
import { computed, ref, watch } from 'vue';
import { PlanCategory } from '@/models/PlanCategory';
import { useCartStore } from '@/store/cart';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import { InstrumentDescription, getInstrumentsForLanguage } from '@/utilities/Instrument';
import { useUserStore } from '@/store/user';
import { storeToRefs } from 'pinia';
import { useProductsStore } from '@/store/products';
import { useLanguage } from '@/composables/useLanguage';
import { useMainStore } from '@/store/main';
import { AppChip, AppLink, ImageChip } from '@sunlabde/m2m-library';

const emits = defineEmits(['signupTrackingEvent', 'continue']);
const loading = ref(false);
const { selectedLanguage } = useLanguage();

const mainStore = useMainStore();
const { selectedPlanCategory } = storeToRefs(mainStore);

const userStore = useUserStore();
const { selectedExperienceLevel } = storeToRefs(userStore);

const productsStore = useProductsStore();
const { comboComparisonByBillingOccurence, allowsTrialOrder, isFetched: plansFetched } = storeToRefs(productsStore);

const instruments = computed(() => getInstrumentsForLanguage(selectedLanguage.value.code as SupportedLocale));

const validateStepSelection = computed(() => selectedPlanCategory.value && (!!selectedExperienceLevel.value || !allowsTrialOrder.value));

const isCombo = computed(() => {
	if (selectedPlanCategory.value) {
		return selectedPlanCategory.value === 'combo';
	}

	return false;
});

const highestComboSaving = computed(() => {
	const savings: number[] = [];

	Object.values(comboComparisonByBillingOccurence.value).forEach((billingOccurence) => {
		savings.push(billingOccurence.savingsInPercent);
	});

	return Math.max(...savings);
});

const selectExperienceLevel = (experienceLevel: ExperienceLevel) => {
	userStore.experienceLevel = experienceLevel;
	emits('signupTrackingEvent', 'selectExperienceLevel');
};

const selectPlanCategory = (planCategory: PlanCategory) => {
	mainStore.planCategory = planCategory;

	emits('signupTrackingEvent', 'selectPlanCategory');

	if (!selectedExperienceLevel.value && allowsTrialOrder.value) {
		userStore.experienceLevel = 'beginner';
	}
};

const cartStore = useCartStore();

const nextStep = () => {
	cartStore.clearCart();
	loading.value = true;

	watch(plansFetched, (fetched) => {
		if (fetched) {
			emits('continue');
		}
	}, { immediate: true });
};

const filteredInstruments = computed(() => {
	if (!isFeatureFlagEnabled(FeatureFlag.DRUMS)) {
		const filteredArray = instruments.value.filter((instrument: InstrumentDescription) => instrument.id !== 'drums');
		return filteredArray;
	}
	return instruments.value;
});

const isCategorySelected = (category: PlanCategory) => mainStore.planCategory === category;
const isHighlighted = computed(() => mainStore.planCategory !== null);
</script>
