<template>
	<div class="h-[110px]">
		<p class="font-bold text-primary-400 mb-4">
			{{ $t('aside.trialTimeline.title') }}
		</p>
		<div class="relative h-[3px] bg-gray-700 rounded-[3px]">
			<div class="absolute h-[3px] w-[70%] bg-primary-400 rounded-[3px]" />
			<div class="absolute top-[3px] left-1/3 p-4 text-xs">
				<div class="w-[1px] h-5 bg-primary-400 absolute top-0 left-0 after:absolute after:w-[11px] after:h-[11px] after:rounded-[50%] after:border after:border-solid after:border-primary-400 after:bottom-[-11px] after:left-1/2 after:transform after:translate-x-[-50%]" />
				<div class="marker-content">
					<p>{{ $t('aside.trialTimeline.marker1', [endOfTrialDate.toFormat('dd.MM.yyyy')]) }}</p>
				</div>
			</div>
			<div class="absolute bottom-[3px] left-[70%] p-4 text-xs text-gray-700">
				<div class="w-[1px] h-[42px] bg-gray-700 absolute bottom-0 left-0 after:absolute after:w-[11px] after:h-[11px] after:rounded-[50%] after:border after:border-solid after:border-gray-700 after:top-[-11px] after:left-1/2 after:transform after:translate-x-[-50%]" />
				<div class="marker-content">
					<p>{{ $t('aside.trialTimeline.marker2') }}</p>
					<p
						class="font-bold text-white text-sm"
						data-cy="aside_trialEnds_date"
					>
						{{ endOfTrialDate.toFormat('dd.MM.yyyy') }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useMainStore } from '@/store/main';
import { storeToRefs } from 'pinia';

const { endOfTrialDate } = storeToRefs(useMainStore());
</script>
