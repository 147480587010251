import ky from 'ky';
import { ResultAsync } from 'neverthrow';
import * as Sentry from '@sentry/vue';

export interface UserIPInfo {
	ip: string;
	city: string;
	region: string;
	country: string;
	loc: string;
	org: string;
	postal: string;
	timezone: string;
	readme: string;
}

export const getUserIPInfo = (): ResultAsync<UserIPInfo, Error> => {
	const response = ky.get('https://ipinfo.io').json() as Promise<UserIPInfo>;

	return ResultAsync.fromPromise(response, (error) => {
		if (error instanceof Error) {
			return error;
		}

		return new Error('Unable to get user information');
	});
};

export const getUserCountryCode = async (): Promise<string | null> => {
	const result = await getUserIPInfo();

	if (result.isErr()) {
		Sentry.captureMessage('Unable to evaluate users current country', {
			contexts: {
				error: {
					errorMessage: result.error.message,
					error: result.error,
				},
			},
		});

		return null;
	}

	return result.value.country;
};
