<template>
	<div class="relative rounded-[14px] p-8 box-border transition duration-375 ease-smooth text-sm bg-gray-200 mb-6">
		<p class="text-lg font-bold mb-6">
			{{ $t('subscribe.step.orderOverview.overview') }}
		</p>
		<div>
			<div
				v-if="selectedPlanVariant"
				class="flex items-center"
			>
				<div
					v-if="themePlan"
					class="w-12 h-12 overflow-hidden rounded-[4px] mr-3"
				>
					<img
						:src="planCategoryImageMap[themePlan]"
						:alt="themePlan"
						data-cy="order_overview_plan_image"
						class="w-full h-full object-cover"
					>
				</div>
				<div
					class="item-name"
					data-cy="order_overview_plan_name"
				>
					{{ getDisplayName(selectedPlanVariant) }}
				</div>
				<div
					class="ml-auto"
					data-cy="order_overview_plan_price"
				>
					{{ getFormattedPrice(totalPriceInCents, currency, { fromCents: true }) }}
				</div>
			</div>
		</div>
		<div
			v-if="selectedPlanVariant"
			class="pt-4 border-t border-solid border-gray-600 mt-4"
		>
			<div
				v-for="(paymentPeriod, $index) in paymentPeriods"
				:key="$index"
				:class="{ 'text-lg font-bold': $index === 0 }"
				class="footer-row flex justify-between"
			>
				<div>
					<p>
						{{ `${localize(paymentPeriod.label)}, ${isTrialOrder ? '' : '*'}` }}
					</p>
					<p
						v-if="getPaymentPeriodDurationNote(paymentPeriods, $index)"
						class="text-2xs text-gray-700"
					>
						{{ localize(getPaymentPeriodDurationNote(paymentPeriods, $index) ?? '') }}
					</p>
				</div>
				<div class="flex items-center">
					<p class="flex items-center">
						<template v-if="paymentPeriod.reductionPercent">
							<span class="font-bold line-through mr-2">{{ getFormattedPrice(paymentPeriod.priceInCents, currency, { fromCents: true }) }}</span>
							<span class="inline-block mr-3 text-green-300 text-xs font-bold">{{ `-${paymentPeriod.reductionPercent}%` }}</span>
							<span>
								{{ getFormattedPrice(getPrice(paymentPeriod), currency, { fromCents: true }) }}
							</span>
						</template>
						<span v-else>
							{{ getFormattedPrice(paymentPeriod.priceInCents, currency, { fromCents: true }) }}
						</span>
					</p>
					<TooltipWithIcon
						v-if="paymentPeriod.reductionPercent"
						:tooltip="{
							content: $t('tooltip.price.adjustedAfterDiscountCode'),
							placement: 'right',
						}"
					/>
				</div>
			</div>
			<div
				v-if="!noPayment && selectedPaymentMethod"
				class="footer-row flex justify-between items-center"
			>
				<div class="row-left">
					{{ $t('subscribe.step.orderOverview.paymentMethod') }}
				</div>
				<div class="flex items-center">
					<img
						v-if="selectedPaymentMethod.image"
						:src="selectedPaymentMethod.image"
						class="w-[42px] h-auto bg-white"
						:alt="selectedPaymentMethod.image"
					>
					<span class="text-2xs ml-2 before:content-['('] after:content-[')']">
						{{ $t(selectedPaymentMethod.name) }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import TooltipWithIcon from '@/components/TooltipWithIcon.vue';
import {
	getBillingOccurenceDescription, getLocalizedDisplayName, getRecurringFeeInCents, PlanVariant,
} from '@/models/PlanVariant';
import { getFormattedPrice } from '@/models/Currency';
import { usePlanVariant } from '@/composables/usePlanVariant';
import { getPaymentPeriods, getPrice, getPaymentPeriodDurationNote } from '@/models/PaymentPeriod';
import { localize } from '@/models/Language';
import { useLanguage } from '@/composables/useLanguage';
import { useCartStore } from '@/store/cart';
import { PlanCategory, planCategoryImageMap } from '@/models/PlanCategory';
import { useMainStore } from '@/store/main';
import { useBillwerkStore } from '@/store/billwerk';
import { useProductsStore } from '@/store/products';

export default defineComponent({
	name: 'CheckoutSummary',

	components: {
		TooltipWithIcon,
	},

	props: {
		noPayment: {
			type: Boolean,
			required: false,
		},
	},

	setup() {
		const { selectedLanguage } = useLanguage();

		const getDisplayName = (planVariant: PlanVariant) => getLocalizedDisplayName(planVariant, selectedLanguage.value.code);

		const cartStore = useCartStore();
		const appliedDiscount = computed(() => cartStore.appliedDiscount);
		const selectedPlanVariant = computed(() => cartStore.cart?.planVariant);

		const themePlan = computed<PlanCategory | null>(() => {
			if (!Object.keys(planCategoryImageMap).includes(cartStore.themeFromSelectedPlan)) {
				return null;
			}

			return cartStore.themeFromSelectedPlan as PlanCategory;
		});

		const productsStore = useProductsStore();
		const { allowsTrialOrder } = storeToRefs(productsStore);

		const mainStore = useMainStore();
		const { endOfTrialDate } = storeToRefs(mainStore);

		const billwerkStore = useBillwerkStore();
		const { selectedPaymentMethod } = storeToRefs(billwerkStore);

		return {
			getDisplayName,
			localize,
			getFormattedPrice,
			getPrice,
			getPaymentPeriodDurationNote,

			allowsTrialOrder,
			themePlan,
			planCategoryImageMap,
			appliedDiscount,
			selectedPlanVariant,
			endOfTrialDate,
			selectedPaymentMethod,
		};
	},

	computed: {
		totalPriceInCents() {
			if (this.noPayment || !this.selectedPlanVariant) {
				return 0;
			}

			return getRecurringFeeInCents(this.selectedPlanVariant);
		},

		currency() {
			return this.selectedPlanVariant ? usePlanVariant(this.selectedPlanVariant).currency.value : 'EUR';
		},

		selectedPlanBillingOccurenceName() {
			if (this.selectedPlanVariant && Object.keys(this.selectedPlanVariant).length > 0) {
				return getBillingOccurenceDescription(this.selectedPlanVariant).name;
			}

			return '';
		},

		isTrialOrder() {
			return this.allowsTrialOrder && !this.noPayment;
		},

		paymentPeriods() {
			if (!this.selectedPlanVariant) {
				return [];
			}

			if (this.isTrialOrder) {
				return getPaymentPeriods({
					planVariant: this.selectedPlanVariant,
					trialEndDate: (this.endOfTrialDate as DateTime).toJSDate(),
					discount: this.appliedDiscount ?? undefined,
				});
			}

			return getPaymentPeriods({
				planVariant: this.selectedPlanVariant,
				discount: this.appliedDiscount ?? undefined,
			});
		},
	},
});
</script>

<style lang="scss" scoped>

.footer-row {
	:deep(.tooltip-icon) {
		@apply mr-0;
	}

	&:not(:first-child) {
		@apply mt-4;
	}
}
</style>
