import { Translation } from './translation.interface';

const translationToString = (translation: Translation) => {
	if (typeof translation === 'function') {
		return translation();
	}

	return translation;
};

export {
	translationToString,
};
