<template>
	<div class="mobileheader w-screen desktop:w-full">
		<div class="flex justify-between items-center h-14 px-6 z-100 relative shadow-sm desktop:shadow-none ">
			<div class="topbar-left">
				<a
					:href="staticLinkComposer.compose('home')"
					class="desktop:hidden"
				>
					<img
						src="@/assets/logo-signet.svg"
						alt="logo"
					>
				</a>
			</div>
			<div class="flex items-center space-x-2">
				<TopbarLanguageMenu />
				<div class="items-center hidden desktop:flex space-x-2">
					<AppLink
						v-for="item in signupNavigationItems"
						:key="item.name"
						:link="String(item.link)"
						class="text-gray-700 hover:text-primary-400"
						target="_blank"
					>
						{{ item.name }}
					</AppLink>
				</div>
			</div>
		</div>
		<div
			v-if="!isFeatureFlagEnabled(FeatureFlag.SOCIAL_LOGIN)"
			class="flex items-center justify-center bg-perspective-cards h-14 desktop:hidden"
		>
			<h1 class="text-white text-lg">
				{{ $t('aside.title.welcome') }}
			</h1>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useSignupNavigation } from '@/utilities/Navigation';
import { useStaticLinkComposer } from '@/utilities/StaticLinks';
import { AppLink } from '@sunlabde/m2m-library';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import TopbarLanguageMenu from './TopbarLanguageMenu.vue';

const { navigation: signupNavigationItems } = useSignupNavigation();
const staticLinkComposer = useStaticLinkComposer();
</script>
