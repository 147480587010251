import { StatusCodes } from 'http-status-codes';
import * as Sentry from '@sentry/vue';
import { HTTPError } from 'ky';
import { APIErrorCodes, AppErrorCodes, AuthErrorCodes } from '@/enums/ErrorCodes';

export interface APIErrorResponseBody {
	errorCode: APIErrorCodes;
	errorMessage: string;
}

export default class AppError extends Error {
	cause?: Error | undefined;

	constructor(errorCode: AuthErrorCodes | AppErrorCodes | APIErrorCodes | string, cause?: HTTPError | Error | unknown) {
		super(errorCode);
		this.name = this.constructor.name;

		if (cause instanceof Error) {
			this.cause = cause;
			Sentry.setContext('App Error Cause', { cause });
		}
	}

	isUnauthorized(): boolean {
		return this.message === AuthErrorCodes.USER_UNAUTHORIZED;
	}

	isForbidden(): boolean {
		return this.message === AuthErrorCodes.MISSING_ACCESS_RIGHTS;
	}

	static async fromHTTPError(error: HTTPError): Promise<AppError> {
		try {
			const response = await error.response.json() as APIErrorResponseBody;
			Sentry.setContext('API Response', response);
			return new AppError(response.errorCode, error);
		} catch (catchedError) {
			if (error.response.status === StatusCodes.FORBIDDEN) {
				return new AppError(AuthErrorCodes.MISSING_ACCESS_RIGHTS, error);
			}

			if (error.response.status === StatusCodes.UNAUTHORIZED) {
				return new AppError(AuthErrorCodes.LOGIN_UNAUTHORIZED, error);
			}

			return new AppError(APIErrorCodes.NO_ERROR_CODE, error);
		}
	}

	static fromMaybeUnknown(error: unknown, fallbackMessage: string): AppError {
		if (error instanceof AppError) {
			return error;
		}

		return new AppError(fallbackMessage, error);
	}
}
