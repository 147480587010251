<template>
	<div class="aside relative flex flex-col bg-perspective-cards bg-center bg-cover desktop:py-8 box-border text-white order-2 phablet:px-20 desktop:px-12 py-6 desktop:fixed desktop:top-0 desktop:left-0 desktop:h-screen desktop:w-[450px] widescreen:w-[600px] overlay overlay-black overlay-opacity-30">
		<div class="z-[1] items-center mb-12 widescreen:mb-[88px] hidden desktop:flex">
			<a
				:href="staticLinkComposer.compose('home')"
				class="flex-shrink-0 max-w-[140px] max-h-[70px]"
			>
				<img
					src="@/assets/logo.svg"
					alt="logo"
				>
			</a>
			<a
				v-if="isThomannStyled"
				class="flex-shrink-0 w-full max-w-[140px] max-h-[70px] border-l pl-4 ml-4 h-full flex items-center"
				href="https://www.thomann.de"
				rel="noopener noreferrer"
				target="_blank"
			>
				<img
					src="@/assets/thomann-cyan-white.svg"
					height="30"
					alt="thomann"
				>
			</a>
			<img
				v-if="portalLogo"
				:src="portalLogo"
				:alt="portalLogo"
				class="flex-shrink-0 max-w-[140px] max-h-[70px] border-r-[1px] border-r-[color:white] pr-4 mr-4"
			>
		</div>
		<div
			v-if="hasBodySlot"
			class="z-[1] max-w-[950px] w-full mx-auto desktop:order-none order-2"
		>
			<slot name="body" />
		</div>
		<div
			v-if="hasFooterSlot"
			class="flex flex-col items-stretch justify-end flex-grow z-[1] max-w-[950px] w-full mx-auto order-1 desktop:order-none px-6 desktop:px-0"
		>
			<slot name="footer" />
		</div>
		<div
			:class="{ 'mt-16': hasBodySlot || hasFooterSlot }"
			class="z-[1] max-w-[950px] w-full mx-auto text-xs order-3 px-6 desktop:hidden"
		>
			<AppLink
				v-for="item in signupNavigationItems"
				:key="item.name"
				:link="formatUrl(item.link)"
				class="text-white hover:text-primary-400 mr-2 last:mr-0"
				target="_blank"
			>
				{{ item.name }}
			</AppLink>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useSignupNavigation } from '@/utilities/Navigation';
import { useStaticLinkComposer } from '@/utilities/StaticLinks';
import { computed, useSlots } from 'vue';
import router from '@/router';
import { RouteLocationRaw } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProductsStore } from '@/store/products';
import { useMainStore } from '@/store/main';
import { AppLink } from '@sunlabde/m2m-library';

const slots = useSlots();

const formatUrl = (location: RouteLocationRaw): string => {
	if (typeof location === 'string') {
		return location;
	}

	return router.resolve(location).href;
};

const signupNavigationItems = useSignupNavigation().navigation; // .value.map(createSignupLinkNavigationItem);
const staticLinkComposer = useStaticLinkComposer();

const { signupStyle } = storeToRefs(useMainStore());

const productsStore = useProductsStore();
const { portal } = storeToRefs(productsStore);

const hasBodySlot = computed(() => !!slots.body);
const hasFooterSlot = computed(() => !!slots.footer);
const isThomannStyled = computed(() => signupStyle.value === 'thomann');
const portalLogo = computed(() => portal.value?.options.logo);
</script>
