import { Discount } from '@/models/Discounts';
import { DiscountError } from '@/utilities/ErrorClasses';
import { errAsync, okAsync, ResultAsync } from 'neverthrow';
import { InternalOrder, InternalOrderCart } from '@/models/InternalOrder';
import { responseWithErrorHandler } from '.';
import contentApiClient from './contentApiClient';

export const lookupDiscountCodeForOrder = (discountCode: string, order: InternalOrder): ResultAsync<Discount, Error> => {
	const orderCopy = { ...order };
	orderCopy.Cart.CouponCode = discountCode;

	const data: Promise<Discount> = contentApiClient.client.post('discount', {
		json: orderCopy,
	}).json();

	return responseWithErrorHandler(data).andThen((discount) => {
		if (!discount.Targets[order.Cart.PlanVariantID]) {
			return errAsync(new DiscountError('codeNotValid'));
		}

		return okAsync(discount);
	}).mapErr(() => new DiscountError('codeNotValid'));
};

export const lookupDiscountCodeInCart = (billwerkCustomerId: string, cart: InternalOrderCart): ResultAsync<Discount, Error> => {
	const data = contentApiClient.client
		.post('discount', {
			json: {
				CustomerId: billwerkCustomerId,
				cart,
			},
		})
		.json<Discount>();

	return responseWithErrorHandler(data);
};

export const lookupDiscountCodeInCarts = (billwerkCustomerId: string, carts: InternalOrderCart[]): ResultAsync<Discount[], Error> => {
	const cartsWithCustomerId = carts.map((cart) => ({
		cart,
		CustomerId: billwerkCustomerId,
	}));

	const data = contentApiClient.client
		.post('discounts', {
			json: cartsWithCustomerId,
		})
		.json<Discount[]>();

	return responseWithErrorHandler(data);
};
