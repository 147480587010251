import { computed, unref } from 'vue';
import { Discount } from '@/models/Discounts';
import {
	getBillingOccurenceDescription, getISOCurrency, getLocalizedDisplayName, getRecurringFee, getRecurringFeeInCents, PlanVariant,
} from '@/models/PlanVariant';
import { MaybeRef } from '@/utilities/Types';
import { useLanguage } from './useLanguage';

export const usePlanVariant = (_planVariant: MaybeRef<PlanVariant>, _discount?: MaybeRef<Discount | null>) => {
	const planVariant = computed(() => unref(_planVariant));
	const discount = computed(() => unref(_discount));

	const recurringFee = computed(() => getRecurringFee(planVariant.value));

	const recurringFeeInCents = computed(() => getRecurringFeeInCents(planVariant.value));

	const hasDiscountApplied = computed(() => !!(discount.value && unref(discount)));

	const planCategory = computed(() => planVariant.value.CustomFields.category);

	const billingOccurence = computed(() => getBillingOccurenceDescription(planVariant.value));

	const currency = computed(() => getISOCurrency(planVariant.value));

	const pricePerOccurenceInCents = computed(() => {
		if (discount.value) {
			return recurringFeeInCents.value * (1 - (discount.value.Effect.ReductionPercent / 100));
		}

		return recurringFeeInCents.value;
	});

	const pricePerMonthInCents = computed(() => {
		if (!planVariant.value.BillingPeriod.Quantity) {
			return 0;
		}

		return pricePerOccurenceInCents.value / billingOccurence.value.months;
	});

	const { selectedLanguage } = useLanguage();

	const localizedDisplayName = computed(() => getLocalizedDisplayName(planVariant.value, selectedLanguage.value.code));

	return {
		currency,
		recurringFee,
		recurringFeeInCents,
		hasDiscountApplied,
		planCategory,
		billingOccurence,
		pricePerOccurenceInCents,
		pricePerMonthInCents,
		localizedDisplayName,
	};
};
