import { inject } from 'vue';
import { createPlausible, PLAUSIBLE_PLUGIN_KEY } from '.';

export const usePlausible = (plausible?: ReturnType<typeof createPlausible>) => {
	const _plausible = plausible ?? inject(PLAUSIBLE_PLUGIN_KEY);

	if (!_plausible) {
		throw new Error('Plausible plugin has not been provided.');
	}

	return _plausible;
};
