import Cookies from 'js-cookie';
import { parseDomain, fromUrl, ParseResultType } from 'parse-domain';

export const COOKIE_API_ACCESS_TOKEN = import.meta.env.VITE_API_ACCESS_TOKEN_COOKIE;

export const COOKIE_API_REFRESH_TOKEN = import.meta.env.VITE_API_REFRESH_TOKEN_COOKIE;

export const COOKIE_USER_INFO = import.meta.env.VITE_USER_INFO_COOKIE;

const getCookieDomain = (): string => {
	const parseResult = parseDomain(fromUrl(window.location.origin));

	if (parseResult.type !== ParseResultType.Listed) {
		return window.location.origin;
	}

	const { domain, topLevelDomains } = parseResult;

	return `${domain}.${topLevelDomains.join('.')}`;
};

export const setCookie = (name: string, value: string, options: Cookies.CookieAttributes = {}): void => {
	Cookies.set(name, value, { domain: getCookieDomain(), expires: 90, ...options });
};

export const removeCookie = (name: string, options: Cookies.CookieAttributes = {}): void => {
	Cookies.remove(name, { domain: getCookieDomain(), ...options });
	Cookies.remove(name, { ...options });
};
