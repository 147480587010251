import { Result, ok, err } from 'neverthrow';
import { UpdateableUserProperties, UserResponse } from '@/models/UserResponse';
import { isTrueValue } from '@/utilities/Helpers';
// import { Gender } from '@/enums/Gender';
import { AuthErrorCodes } from '@/enums/ErrorCodes';
import AppError from './AppError';

export interface AuthUserProfileData {
	email: string;
	username: string;
	// gender: Gender;
	gender: string;
	birthdate: string | null;
	twitterURL: string;
	facebookURL: string;
	country?: string;
}

export interface AuthUserEmailSettings {
	newsletterOptIn: boolean;
}

export interface AuthUserOptions extends AuthUserProfileData, AuthUserEmailSettings {
	userId: string;
	avatarURL: string;
	registeredDate: Date;
	role: string;
	lastname: string;
	firstname: string;
	profileId: string;
	emailVerified: boolean;
	intercomHash?: string;
}

export class AuthUser implements AuthUserOptions {
	username: string;
	userId: string;
	profileId: string;
	email: string;
	lastname: string;
	firstname: string;
	newsletterOptIn: boolean;
	avatarURL: string;
	registeredDate: Date;
	role: string;
	// gender: Gender;
	gender: string;
	birthdate: string | null;
	twitterURL: string;
	facebookURL: string;
	country?: string;
	emailVerified: boolean;
	intercomHash?: string;

	constructor(options: AuthUserOptions) {
		this.username = options.username;
		this.userId = options.userId;
		this.email = options.email;
		this.lastname = options.lastname;
		this.firstname = options.firstname;
		this.newsletterOptIn = options.newsletterOptIn;
		this.avatarURL = options.avatarURL;
		this.registeredDate = options.registeredDate;
		this.role = options.role;
		this.gender = options.gender;
		this.birthdate = options.birthdate;
		this.twitterURL = options.twitterURL;
		this.facebookURL = options.facebookURL;
		this.profileId = options.profileId;
		this.emailVerified = options.emailVerified;
		this.intercomHash = options.intercomHash;
		this.country = options.country;
	}

	get displayName() {
		if (this.username) {
			return this.username;
		}

		if (this.firstname) {
			return this.firstname;
		}

		return this.email;
	}

	static fromUserResponse(userResponse: UserResponse): AuthUser {
		return new AuthUser({
			username: userResponse.nickname || '',
			userId: userResponse.sub,
			email: userResponse.email,
			lastname: userResponse.family_name || '',
			firstname: userResponse.given_name || '',
			newsletterOptIn: isTrueValue(userResponse.newsletter_opt_in || ''),
			avatarURL: userResponse.picture,
			registeredDate: new Date(userResponse.registered_at),
			role: userResponse.role,
			// gender: userResponse.gender || Gender.UNSET,
			gender: userResponse.gender || '',
			birthdate: userResponse.birthdate || '',
			twitterURL: userResponse.twitter || '',
			facebookURL: userResponse.facebook || '',
			profileId: userResponse.profil_id || '',
			emailVerified: userResponse.email_verified ? isTrueValue(userResponse.email_verified) : false,
			intercomHash: userResponse.intercom_hash,
			country: userResponse.country,
		});
	}

	static fromUserResponseSafely = (userResponse: UserResponse): Result<AuthUser, AppError> => {
		try {
			const user = AuthUser.fromUserResponse(userResponse);
			return ok(user);
		} catch (error) {
			return err(new AppError(AuthErrorCodes.USER_RESPONSE_INVALID, error));
		}
	};
}

export const userProfileToUpdateableUserProperties = (userProfile: Partial<AuthUserProfileData>): UpdateableUserProperties => ({
	birthdate: userProfile.birthdate === null ? '' : userProfile.birthdate,
	gender: userProfile.gender,
	nickname: userProfile.username,
	facebook: userProfile.facebookURL,
	twitter: userProfile.twitterURL,
});

export const userEmailSettingsToUpdateableUserProperties = (userEmailSettings: Partial<AuthUserEmailSettings>): UpdateableUserProperties => ({
	newsletterOptIn: userEmailSettings.newsletterOptIn,
});
