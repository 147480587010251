import { useScriptTag } from '@vueuse/core';
import { ref } from 'vue';
import * as Sentry from '@sentry/vue';
import { GoogleRecaptcha } from './model';

export const useGoogleRecaptcha = () => {
	const grecaptcha = ref<GoogleRecaptcha>();

	useScriptTag(`https://www.google.com/recaptcha/api.js?render=${import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}`, () => {
		grecaptcha.value = window.grecaptcha;
	});

	const getUserTokenForAction = (action: string) => new Promise<string>((resolve) => {
		if (!grecaptcha.value) {
			Sentry.captureMessage('Failed to generate Google ReCAPTCHA v3 user token because window.grecaptcha was not defined', 'warning');
			return;
		}

		grecaptcha.value.ready(async () => {
			const userToken = await grecaptcha.value?.execute(import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY, { action }) ?? '';
			resolve(userToken);
		});
	});

	return {
		getUserTokenForAction,
	};
};
