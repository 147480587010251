import logger from '@/plugins/Logger';
import { usePlausible } from '@/plugins/plausible/usePlausible';
import { useGtm } from '@gtm-support/vue-gtm';

export const useSignupTracking = () => {
	const plausible = usePlausible();
	const gtm = useGtm();

	const trackNewSignup = () => {
		logger.info('New User created, successfully tracked signup');
		plausible.trackEvent('Signup');
		gtm?.trackEvent({ event: 'registrationComplete' });
	};

	return {
		trackNewSignup,
	};
};
