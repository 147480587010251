import { NewUserData } from '@/models/NewUserData';
import { responseWithErrorHandler } from '.';
import contentApiClient from './contentApiClient';

export const createUser = (user: NewUserData) => {
	const data: Promise<Response> = contentApiClient.client.post('user', {
		headers: {
			'api-version': '3.2',
		},
		json: user,
	}).json();

	return responseWithErrorHandler(data);
};
