import type { Component } from 'vue';
import BlankLayout from '@/layouts/LayoutBlank.vue';
import SignupLayout from '@/layouts/LayoutSignup.vue';

export type LayoutSlug = 'blank' | 'signup';

export const layoutComponentMap: Record<LayoutSlug, Component> = {
	blank: BlankLayout,
	signup: SignupLayout,
};

export const getLayoutComponent = (slug: LayoutSlug): Component => layoutComponentMap[slug];
