import { ResultAsync } from 'neverthrow';
import { AuthUser } from '@/models/AuthUser';
import { UpdateableUserProperties } from '@/models/UserResponse';
import { AppErrorCodes } from '@/enums/ErrorCodes';
import AppError from '@/models/AppError';
import { APIClient, responseWithErrorHandler } from '.';
import contentApiClient from './contentApiClient';

export const refreshPrivilgesForUser = (user: AuthUser) => {
	const data: Promise<Response> = contentApiClient.client.post(`user/${user.userId}/refreshPrivileges`, {
		headers: {
			'api-version': '3.1',
		},
	}).json();

	return responseWithErrorHandler(data);
};

export const updateUserProfile = (userId: string, userProperties: Partial<UpdateableUserProperties>): ResultAsync<Response, Error> => {
	const onError = (error: Error) => new AppError(AppErrorCodes.PROFILE_COULD_NOT_UPDATE, error);

	const data: Promise<Response> = APIClient.useWithLoader(contentApiClient.client.put(`user/${userId}/profile`, {
		headers: {
			'Content-Type': 'application/json',
		},
		json: {
			...userProperties,
		},
	}));

	return responseWithErrorHandler(data, onError);
};
