import {
	createRouter, createWebHistory, RouteLocationNormalized, START_LOCATION,
} from 'vue-router';
import { ensureAppVersionOrReload } from '@/models/AppVersionTracker';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useGtm } from '@gtm-support/vue-gtm';
import { ResultAsync } from 'neverthrow';
import i18n, { setI18nLanguage } from '@/plugins/i18n';
import { nextTick } from 'vue';
import { appProgressLoader } from '@/plugins/AppProgressLoader';
import {
	createRedirectToQuery, getFeatureFlagQuery, getLocaleFromRouteOrStorage, hasRouteValidLocale, requiresValidLocale,
} from './helpers';
import { routes } from './routes';
import { setRouteLayout } from './navigationGuards';

const router = createRouter({
	history: createWebHistory(import.meta.env.VITE_BASE_URL),
	routes,
});

const hasTrackedLocale = { value: false };

const handleLocaleTracking = (route: RouteLocationNormalized) => {
	const gtm = useGtm();

	if (hasTrackedLocale.value) {
		return;
	}

	const locale = getLocaleFromRouteOrStorage(route);

	gtm?.trackEvent({
		event: 'locale',
		locale,
	});

	hasTrackedLocale.value = true;
};

router.beforeEach(async (to, from, next) => {
	const authStore = useAuthStore();
	const userStore = useUserStore();

	if (from === START_LOCATION) {
		await authStore.initAuthentication()
			.andThen((user) => ResultAsync.fromSafePromise(userStore.maybeAddCountryToUserProfile(user)));
	}

	await ensureAppVersionOrReload(to.fullPath);

	appProgressLoader.start();

	// TODO: Rework to split "setLocale" from the fact if the locale is supported in general
	if (requiresValidLocale(to) && !hasRouteValidLocale(to)) {
		const locale = getLocaleFromRouteOrStorage(to);

		setI18nLanguage(i18n, locale);

		const newParams = { ...to, params: { ...to.params, lang: locale } };

		return next(newParams);
	}

	handleLocaleTracking(to);

	if (to.matched.some((route) => route.meta.requiresAuth)) {
		if (authStore.isAuthenticated) {
			return next();
		}

		return next({ name: 'signup', query: createRedirectToQuery(to), params: { lang: to.params.lang } });
	}

	if (to.matched.some((route) => route.meta.requiresGuest)) {
		if (!authStore.isAuthenticated) {
			return next();
		}

		return next({ name: 'subscribe', query: getFeatureFlagQuery(to), params: { lang: to.params.lang } });
	}

	return next();
});

router.afterEach(setRouteLayout);

router.afterEach((to) => {
	nextTick(() => {
		let title = 'music2me';

		if (to.meta.title) {
			title = `${typeof to.meta.title === 'function' ? to.meta.title(to) : to.meta.title} - ${title}`;
		}

		document.title = import.meta.env.MODE === 'development' ? `⚙️ ${title}` : title;
	});

	appProgressLoader.finishAll();
});

export default router;
