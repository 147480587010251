import { App, InjectionKey } from 'vue';
import Intercom, { IntercomOptions } from './model';

export const INTERCOM_KEY: InjectionKey<Intercom> = Symbol('Intercom');

export default {
	install(app: App, options: IntercomOptions) {
		const intercom = new Intercom(options);

		app.provide(INTERCOM_KEY, intercom);
	},
};
