import { DateTime } from 'luxon';
import { KeysToPascalCase } from './Types';

export const ensureWaitTime = async (start: DateTime, end: DateTime, waitTimeInMS: number): Promise<true> => new Promise((resolve) => {
	const difference = end.diff(start, 'milliseconds').milliseconds;

	if (difference < waitTimeInMS) {
		setTimeout(() => {
			resolve(true);
		}, waitTimeInMS - difference);
	} else {
		resolve(true);
	}
});

export const loadAsyncScript = (src: string, done: () => void) => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.src = src;
	script.onload = done;

	const firstScript = document.getElementsByTagName('script')[0];

	if (!firstScript.parentNode) {
		return;
	}

	firstScript.parentNode.insertBefore(script, firstScript);
};

export const getLocaleFromPlanGroupId = (planGroupId: string) => {
	if (planGroupId === import.meta.env.VITE_BILLWERK_PLANGROUP_ID_DE) {
		return 'de';
	}

	if (planGroupId === import.meta.env.VITE_BILLWERK_PLANGROUP_ID_EN) {
		return 'en';
	}

	if (planGroupId === import.meta.env.VITE_BILLWERK_PLANGROUP_ID_ES) {
		return 'es';
	}

	return 'de';
};

export const isEdgeBrowser = () => {
	if (document.documentMode || /Edge/.test(navigator.userAgent)) {
		return true;
	}

	return false;
};

export const billwerkDateUnitToMomentMap = {
	Year: 'years',
	Month: 'months',
	Week: 'weeks',
	Day: 'days',
	Hour: 'hours',
	Minute: 'minutes',
	Second: 'seconds',
};

export const isFloat = (n: number) => Number(n) === n && n % 1 !== 0;

export const isTrueValue = (value?: string): boolean => {
	if (typeof value !== 'string') {
		return false;
	}

	return value.toLowerCase() === 'true';
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeObjectKeys = <T extends Object>(obj: T): KeysToPascalCase<T> => Object.entries(obj).reduce((newObj, [key, value]) => {
	const capitalizedKey = capitalize(key);

	return {
		...newObj,
		[capitalizedKey]: value,
	};
}, {} as KeysToPascalCase<T>);

export const toInt = (string: string) => parseInt(string, 10);
