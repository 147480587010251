export type TokenResponseRaw = {
	scope: string;
	token_type: string;
	access_token: string;
	expires_in: number;
	refresh_token: string;
	account_created?: boolean;
}

export type TokenResponse = TokenResponseRaw & {
	account_created: boolean;
	expires_at: Date;
}

export const toTokenResponse = (tokenResponseRaw: TokenResponseRaw): TokenResponse => {
	const expiryDate = new Date();

	expiryDate.setSeconds((expiryDate.getSeconds() + tokenResponseRaw.expires_in) - 60);

	return {
		...tokenResponseRaw,
		account_created: tokenResponseRaw.account_created ?? false,
		expires_at: expiryDate,
	};
};
