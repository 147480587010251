<template>
	<form
		ref="observer"
		tag="form"
		class="form"
		data-cy="form_billwerk_creditcard"
		@submit.prevent="submit"
	>
		<p class="text-left font-bold mb-4">
			{{ $t('subscribe.step.plan.billing') }}
		</p>
		<div class="grid desktop:grid-cols-2 grid-cols-1 gap-3 mb-3">
			<div class="grid gap-4">
				<AppInput
					v-model="street"
					:label="$t('input.street')"
					:is-valid="streetMeta.valid"
					icon="road"
					:error-message="streetErrors[0]"
					tag="input"
					data-cy="input_cc_address"
					@submit="submit"
					@blur="streetBlurHandler"
				/>
				<AppInput
					v-model="postalCode"
					:label="$t('input.postal')"
					:is-valid="postalCodeMeta.valid"
					:error-message="postalCodeErrors[0]"
					icon="mailbox"
					tag="input"
					data-cy="input_cc_postalCode"
					@submit="submit"
					@blur="postalCodeBlurHandler"
				/>
			</div>
			<div class="grid gap-4">
				<AppInput
					v-model="city"
					:label="$t('input.city')"
					:is-valid="cityMeta.valid"
					:error-message="cityErrors[0]"
					icon="city"
					tag="input"
					data-cy="input_cc_city"
					@submit="submit"
					@blur="cityBlurHandler"
				/>
				<AppSelect
					v-model="country"
					:options="mappedAvailableCountries"
					:is-valid="countryMeta.valid"
					:error-message="countryErrors[0]"
					:label="$t('input.country')"
					required
					searchable
					icon="globe-europe"
					@blur="countryBlurHandler"
				/>
				<AppInput
					v-show="selectedCountry?.alpha3 === 'usa'"
					v-model="state"
					:is-valid="stateMeta.valid"
					:error-message="stateErrors[0]"
					:label="$t('input.state')"
					icon="city"
					tag="input"
					data-cy="input_cc_state"
					@submit="submit"
					@blur="stateBlurHandler"
				/>
			</div>
		</div>
		<p>{{ $t('subscribe.step.plan.creditcard.note') }}</p>
	</form>
</template>

<script lang="ts" setup>
import {
	computed, onActivated, onMounted, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useField, useForm } from 'vee-validate';
import { countries } from '@/utilities/Countries';
import { Country } from '@/models/Country';
import { type SelectOption, getOptionValue } from '@/models/Select';
import { useAuthStore } from '@/store/auth';
import { AppSelect } from '@sunlabde/m2m-library';

interface SignupForm {
	street: string;
	postalCode: string;
	city: string;
	country: string;
	state?: string;
}

const emit = defineEmits(['change', 'success', 'update:customerChange', 'update:secretPaymentData']);

const { t, locale } = useI18n();

const { meta: formMeta, validate: validateForm, setTouched } = useForm<SignupForm>();

const {
	value: street,
	errors: streetErrors,
	meta: streetMeta,
	handleBlur: streetBlurHandler,
} = useField<string>(
	'street',
	{ required: true },
	{ label: t('input.street'), initialValue: '' },
);

const {
	value: postalCode,
	errors: postalCodeErrors,
	meta: postalCodeMeta,
	handleBlur: postalCodeBlurHandler,
} = useField<string>(
	'postalCode',
	{ required: true, postcode: { country: '@selectedCountry' } },
	{ label: t('input.postal'), initialValue: '' },
);

const {
	value: city,
	errors: cityErrors,
	meta: cityMeta,
	handleBlur: cityBlurHandler,
} = useField<string>(
	'city',
	{ required: true, alpha_spaces: true },
	{ label: t('input.city'), initialValue: '' },
);

const {
	value: country,
	errors: countryErrors,
	meta: countryMeta,
	handleBlur: countryBlurHandler,
} = useField<SelectOption>(
	'country',
	{ required: true },
	{ label: t('input.country'), initialValue: '' },
);

const {
	value: state,
	errors: stateErrors,
	meta: stateMeta,
	handleBlur: stateBlurHandler,
} = useField<string>(
	'state',
	{ alpha_spaces: true },
	{ label: t('input.state'), initialValue: '' },
);

const language = computed(() => locale.value);

const availableCountries = computed((): Country[] => {
	if (Object.keys(countries).includes(language.value)) {
		const lang = language.value as keyof typeof countries;

		return countries[lang];
	}

	return countries.en;
});

const mappedAvailableCountries = computed((): SelectOption[] => availableCountries.value.map((countryElement) => ({
	value: countryElement.id.toString(),
	label: countryElement.name,
})));

const selectedCountry = computed((): Country | null => availableCountries.value?.find((availableCountry) => availableCountry.id.toString() === getOptionValue(country.value)) ?? null);

/**
 * Because this Component is most likely be used in a <keep-alive> environment it's
 * important that we also make use of the activated lifecycle to emit to current validation state
 * https://vuejs.org/api/options-lifecycle.html#activated
 */
onActivated(() => {
	emit('change', formMeta.value.valid);
});

onMounted(async () => {
	emit('change', formMeta.value.valid);
});

watch(() => formMeta.value, async () => {
	emit('change', formMeta.value.valid);
});

const authStore = useAuthStore();

const submit = async () => {
	const validationResponse = await validateForm();

	if (!validationResponse.valid) {
		setTouched({
			street: true,
			postalCode: true,
			city: true,
			country: true,
		});
		return;
	}

	const formAddress = {
		Street: street.value,
		PostalCode: postalCode.value,
		City: city.value,
		Country: selectedCountry.value?.alpha2.toUpperCase(),
	};

	emit('update:customerChange', {
		FirstName: authStore.user?.firstname,
		LastName: authStore.user?.lastname,
		Address: formAddress,
	});

	emit('update:secretPaymentData', null);

	emit('success');
};

defineExpose({ submit });
</script>
