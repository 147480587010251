<template>
	<div class="relative rounded-[14px] shadow-md p-8 box-border transition duration-375 ease-smooth bg-white flex text-gray-700 text-xs">
		<div
			v-if="image"
			class="mr-4"
		>
			<img
				:src="image"
				:alt="image"
			>
		</div>
		<div>
			<p
				v-if="hasTitleSlot"
				class="text-black font-bold text-sm mb-1"
			>
				<slot name="title" />
			</p>
			<p><slot name="text" /></p>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useSlots, computed } from 'vue';

defineProps({
	image: {
		type: String,
		required: false,
		default: '',
	},
});

const slot = useSlots();
const hasTitleSlot = computed(() => !!slot.title);
</script>
