export default {
	params: [
		'focus',
	],
	// TODO: fix model
	mounted(el: HTMLInputElement, bindings: any) {
		if (!Object.prototype.hasOwnProperty.call(bindings, 'value') || bindings.value) {
			el.focus();
		}
	},
};
