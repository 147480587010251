import { RouteLocationRaw } from 'vue-router';
import type { IconProp } from '@sunlabde/m2m-library';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { translationToString } from '@/types/translation.helper';
import { Translation } from '@/types/translation.interface';
import { composeStaticLink } from './StaticLinks';

export class NavigationItem {
	translation: Translation;
	link: RouteLocationRaw;
	icon?: IconProp;

	constructor({ name, link, icon }: { name: Translation; link: RouteLocationRaw; icon?: IconProp }) {
		this.translation = name;
		this.link = link;
		this.icon = icon;
	}

	get name(): string {
		return translationToString(this.translation);
	}
}

export const useSignupNavigation = () => {
	const { t, locale } = useI18n();

	const navigation = computed<NavigationItem[]>(() => [
		new NavigationItem({
			name: () => t('navigation.imprint'),
			link: composeStaticLink('imprint', locale.value),
		}),
		new NavigationItem({
			name: () => t('navigation.tos'),
			link: composeStaticLink('termsOfUse', locale.value),
		}),
		new NavigationItem({
			name: () => t('navigation.privacyPolicy'),
			link: composeStaticLink('dataPrivacy', locale.value),
		}),
	]);

	return {
		navigation,
	};
};
