import ISO6391, { LanguageCode } from 'iso-639-1';
import { TranslateResult } from 'vue-i18n';
import { SUPPORTED_LOCALES } from '@/utilities/Constants';

export type Language = { code: LanguageCode; name: string; nativeName: string; };
export type LanguageDictionary = Record<string, Language>;
export type SupportedLocale = typeof SUPPORTED_LOCALES[number];
export type TranslationString = string | (() => TranslateResult | string);

export const localeToLanguage = (dictionary: LanguageDictionary, locale: LanguageCode): LanguageDictionary => {
	const language: Language = {
		code: locale,
		name: ISO6391.getName(locale),
		nativeName: ISO6391.getNativeName(locale),
	};

	Object.assign(dictionary, {
		[locale]: language,
	});

	return dictionary;
};

export const supportedLanguages = SUPPORTED_LOCALES.reduce(localeToLanguage, {});

export const isLocaleSupported = (locale: string): boolean => Object.keys(supportedLanguages).includes(locale);

export const localize = (input: TranslationString): string => {
	if (typeof input === 'function') {
		return input() as string;
	}

	return input;
};
