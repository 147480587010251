import { ToastContent, ToastOptions, TYPE } from '@sunlabde/m2m-library';
import i18n from '@/plugins/i18n';
import { Translation } from '@/types/translation.interface';
import {
	APIErrorCodes, CreateSubscriptionErrorCodes, CouponErrorCodes, DiscountErrorCodes, FormErrorCodes, CreateUserErrorCodes, BillwerkErrorCodes,
} from '@/enums/ErrorCodes';
import AdvancedToastComponent from '@/components/AdvancedToast.vue';
import { useCustomErrorModal } from '@/store/customErrorModal';
import { useRouter } from 'vue-router';
import ErrorSmallImage from '@/assets/error_small.png';

const { t } = i18n.global;

/**
 * ESLint is throwing an error when using unnecessary quotes around a property.
 * For better readability and to have the same look for each line of code, we
 * decided to disable ESLint for this file.
 */

/* eslint-disable quote-props */

export interface ToastConfig {
	content: ToastContent;
	options?: ToastOptions;
}

export type ErrorMappingCallback = () => void;

export interface ErrorMapping {
	[key: string]: string | ErrorMappingCallback | ToastConfig;
}

interface AdvancedToastOptions {
	title?: Translation;
	text: Translation;
	options?: ToastOptions;
}

class AdvancedToast implements ToastConfig {
	content: ToastContent;
	options?: ToastOptions;

	constructor(options: AdvancedToastOptions) {
		this.content = {
			component: AdvancedToastComponent,
			props: {
				text: options.text,
			},
		};

		if (options.title) {
			this.content.props.title = options.title;
		}

		this.options = options.options;
	}
}

const errorMapping: ErrorMapping = {
	'auth_error': {
		content: {
			component: AdvancedToastComponent,
			props: {
				title: () => t('notification.error.auth.generic.title'),
				text: () => t('notification.error.auth.generic.text'),
			},
		},
		options: {
			type: TYPE.ERROR,
		},
	},

	[APIErrorCodes.GENERIC]: new AdvancedToast({
		title: () => t('notification.error.login.wrongCredentials.title'),
		text: () => t('notification.error.login.wrongCredentials.text'),
		options: {
			type: TYPE.ERROR,
		},
	}),

	[APIErrorCodes.NO_ERROR_CODE]: new AdvancedToast({
		title: () => t('notification.error.login.wrongCredentials.title'),
		text: () => t('notification.error.login.wrongCredentials.text'),
		options: {
			type: TYPE.ERROR,
		},
	}),

	[CreateSubscriptionErrorCodes.NO_NEW_PRIVILEGES]: () => {
		const store = useCustomErrorModal();
		const router = useRouter();

		store.setCustomErrorModal({
			image: ErrorSmallImage,
			title: 'modal.customError.noNewPrivileges.title',
			text: 'modal.customError.noNewPrivileges.text',
			buttons: [
				{
					text: 'button.backToSubscribeStart',
					props: {
						color: 'lightgrey',
					},
					handler: () => {
						router.push({ name: 'subscribe', query: { step: 1 } });
					},
				},
			],
		});
	},

	[CouponErrorCodes.COUPON_GRANTS_LESS_PRIVILEGES]: {
		content: () => t('notification.error.coupon.grantsLessPrivileges'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CouponErrorCodes.COUPON_ALREADY_USED]: {
		content: () => t('notification.error.coupon.invalid'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CouponErrorCodes.COUPON_NOT_VALID]: {
		content: () => t('notification.error.coupon.invalid'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[DiscountErrorCodes.DISCOUNT_CODE_NOT_VALID]: {
		content: () => t('notification.error.discount.code.invalid'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[DiscountErrorCodes.BILLWERK_INVALID_COUPON_CODE]: {
		content: () => t('notification.error.discount.code.invalid'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[FormErrorCodes.GENERIC]: {
		content: () => t('notification.error.form.global'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.API_ERROR_NOT_FOUND]: {
		content: () => t('notification.error.notFound'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.API_LOGIN_FAIL]: {
		content: () => t('notification.error.invalidUserData'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.API_LOGIN_NOT_APPROVED]: {
		content: () => t('notification.error.loginUserNotApproved'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.PROFIL_FIRSTNAME]: {
		content: () => t('notification.error.field.firstname'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.PROFIL_LASTNAME]: {
		content: () => t('notification.error.field.lastname'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.DUPLICATE_USER_NAME]: {
		content: () => t('notification.error.userAlreadyExists'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.EMAIL_ALREADY_EXISTS]: {
		content: () => t('notification.error.userAlreadyExists'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[CreateUserErrorCodes.RESET_PASSWORD_UNKNOWN]: {
		content: () => t('notification.error.passwordResetGlobal'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[BillwerkErrorCodes.PAYMENT_FAILED]: {
		content: () => t('notification.error.paymentFailed'),
		options: {
			type: TYPE.ERROR,
		},
	},

	[BillwerkErrorCodes.NOT_CORRECT_PAYMENT_DATA_PROVIDED]: {
		content: () => t('notification.error.invalidPaymentDetails'),
		options: {
			type: TYPE.ERROR,
		},
	},

	'form_error.inputsMissing': t('notification.error.form.inputsMissing'),
};

export default errorMapping;
