<template>
	<div>
		<p
			v-if="displayTitle"
			class="font-bold"
		>
			{{ displayTitle }}
		</p>
		<p class="font-normal">
			{{ displayText }}
		</p>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
	name: 'AdvancedToast',

	props: {
		title: {
			type: [String, Function],
			default: '',
		},
		text: {
			type: [String, Function],
			required: true,
		},
	},

	setup(props) {
		const displayTitle = computed((): string => {
			if (typeof props.title === 'function') {
				return props.title();
			}

			return props.title;
		});

		const displayText = computed((): string => {
			if (typeof props.text === 'function') {
				return props.text();
			}

			return props.text;
		});

		return {
			displayTitle,
			displayText,
		};
	},
});
</script>
