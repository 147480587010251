<template>
	<form
		tag="form"
		class="form"
		data-cy="form_billwerk_creditcard"
		@submit.prevent="submit"
	>
		<div class="grid desktop:grid-cols-2 grid-cols-1 gap-3">
			<div>
				<p class="text-left font-bold mb-4">
					{{ $t('subscribe.step.plan.directdebit.title_cardData') }}
				</p>
				<div class="grid gap-4">
					<AppInput
						v-model="accountHolder"
						:label="$t('input.accountHolder')"
						:is-valid="accountHolderMeta.valid"
						:error-message="accountHolderErrors[0]"
						tag="input"
						icon="user"
						data-cy="input_lsv_cardholder"
						@submit="submit"
						@blur="accountHolderBlurHandler"
					/>
					<AppInput
						v-model="iban"
						:label="'IBAN'"
						:is-valid="ibanMeta.valid"
						:error-message="ibanErrors[0]"
						tag="input"
						icon="credit-card"
						data-cy="input_lsv_iban"
						@submit="submit"
						@blur="ibanBlurHandler"
					/>
				</div>
			</div>
			<div>
				<p class="text-left font-bold mb-4">
					{{ $t('subscribe.step.plan.directdebit.title_invoiceData') }}
				</p>
				<div class="grid gap-4">
					<AppInput
						v-model="street"
						:label="$t('input.street')"
						:is-valid="streetMeta.valid"
						:error-message="streetErrors[0]"
						icon="road"
						tag="input"
						data-cy="input_lsv_street"
						@submit="submit"
						@blur="streetBlurHandler"
					/>
					<AppInput
						v-model="postalCode"
						:label="$t('input.postal')"
						:is-valid="postalCodeMeta.valid"
						:error-message="postalCodeErrors[0]"
						icon="mailbox"
						tag="input"
						data-cy="input_lsv_postalcode"
						@submit="submit"
						@blur="postalCodeBlurHandler"
					/>
					<AppInput
						v-model="city"
						:label="$t('input.city')"
						:is-valid="cityMeta.valid"
						:error-message="cityErrors[0]"
						icon="city"
						tag="input"
						data-cy="input_lsv_city"
						@submit="submit"
						@blur="cityBlurHandler"
					/>
					<AppSelect
						v-model="country"
						:options="mappedAvailableCountries"
						:is-valid="countryMeta.valid"
						:error-message="countryErrors[0]"
						:label="$t('input.country')"
						required
						searchable
						icon="globe-europe"
						@blur="countryBlurHandler"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script lang="ts" setup>
import {
	computed, onActivated, onMounted, watch,
} from 'vue';
import { electronicFormatIBAN } from 'ibantools';
import { countries } from '@/utilities/Countries';
import { useField, useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { type SelectOption, getOptionValue } from '@/models/Select';
import { useAuthStore } from '@/store/auth';
import { AppSelect } from '@sunlabde/m2m-library';

interface Country {
	id: number;
	name: string;
	alpha2: string;
	alpha3: string;
}

interface SignupForm {
	accountHolder: string,
	iban: string,
	street: string;
	postalCode: string;
	city: string;
	country: string;
}

const emit = defineEmits(['change', 'success', 'update:customerChange', 'update:secretPaymentData']);

const { t, locale } = useI18n();

const { meta: formMeta, validate: validateForm, setTouched } = useForm<SignupForm>();

const {
	value: accountHolder,
	errors: accountHolderErrors,
	meta: accountHolderMeta,
	handleBlur: accountHolderBlurHandler,
} = useField<string>(
	'accountHolder',
	{ required: true },
	{ label: t('input.accountHolder'), initialValue: '' },
);

const {
	value: iban,
	errors: ibanErrors,
	meta: ibanMeta,
	handleBlur: ibanBlurHandler,
} = useField<string>(
	'iban',
	{ iban: true, required: true },
	{ label: 'IBAN', initialValue: '' },
);

const {
	value: street,
	errors: streetErrors,
	meta: streetMeta,
	handleBlur: streetBlurHandler,
} = useField<string>(
	'street',
	{ required: true },
	{ label: t('input.street'), initialValue: '' },
);

const {
	value: postalCode,
	errors: postalCodeErrors,
	meta: postalCodeMeta,
	handleBlur: postalCodeBlurHandler,
} = useField<string>(
	'postalCode',
	{ required: true, postcode: { country: '@selectedCountry' } },
	{ label: t('input.postal'), initialValue: '' },
);

const {
	value: city,
	errors: cityErrors,
	meta: cityMeta,
	handleBlur: cityBlurHandler,
} = useField<string>(
	'city',
	{ required: true, alpha_spaces: true },
	{ label: t('input.city'), initialValue: '' },
);

const {
	value: country,
	errors: countryErrors,
	meta: countryMeta,
	handleBlur: countryBlurHandler,
} = useField<SelectOption>(
	'country',
	{ required: true },
	{ label: t('input.country'), initialValue: '' },
);

/**
 * Because this Component is most likely be used in a <keep-alive> environment it's
 * important that we also make use of the activated lifecycle to emit to current validation state
 * https://vuejs.org/api/options-lifecycle.html#activated
 */
onActivated(() => {
	emit('change', formMeta.value.valid);
});

onMounted(() => {
	emit('change', formMeta.value.valid);
});

watch(() => formMeta.value, () => {
	emit('change', formMeta.value.valid);
});

const language = computed(() => locale.value);

const availableCountries = computed((): Country[] => {
	if (Object.keys(countries).includes(language.value)) {
		const lang = language.value as keyof typeof countries;

		return countries[lang];
	}

	return countries.en;
});

const mappedAvailableCountries = computed((): SelectOption[] => availableCountries.value.map((countryElement) => ({
	value: countryElement.id.toString(),
	label: countryElement.name,
})));

const selectedCountry = computed((): Country | null => availableCountries.value?.find((availableCountry) => availableCountry.id.toString() === getOptionValue(country.value)) ?? null);

const authStore = useAuthStore();

const submit = async () => {
	const validationResponse = await validateForm();

	if (!validationResponse.valid) {
		setTouched({
			accountHolder: true,
			iban: true,
			street: true,
			postalCode: true,
			city: true,
			country: true,
		});
		return;
	}

	emit('update:customerChange', {
		FirstName: authStore.user?.firstname,
		LastName: authStore.user?.lastname,
		Address: {
			Street: street.value,
			PostalCode: postalCode.value,
			City: city.value,
			Country: selectedCountry.value?.alpha2.toUpperCase(),
		},
	});

	emit('update:secretPaymentData', {
		bearer: 'Debit:Heidelpay',
		accountHolder: accountHolder.value,
		iban: electronicFormatIBAN(iban.value),
		mandateReference: 'customersMandateReference',
		mandateSignatureDate: DateTime.now(),
		mandateText: 'yourMandateText',
	});

	emit('success');
};

defineExpose({ submit });
</script>
