import {
	BillwerkPaymentService, CustomerChange, FinalizeData, SecretPaymentData, SubscriptionJSFinalize,
} from '@/models/Billwerk';
import { SupportedLocale } from '@/models/Language';
import paymentMethods from '@/utilities/PaymentMethods';
import { useScriptTag } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import contentApiClient from '@/api/contentApiClient';
import { errAsync, okAsync } from 'neverthrow';
import { capitalizeObjectKeys } from '@/utilities/Helpers';
import logger from '@/plugins/Logger';
import AppError from '@/models/AppError';
import { AppErrorCodes } from '@/enums/ErrorCodes';
import { InternalOrder } from '@/models/InternalOrder';
import { Order } from '@/models/Order';
import { KeysToPascalCase } from '@/utilities/Types';
import { useUserStore } from './user';
import { useCartStore } from './cart';
import { useProductsStore } from './products';

export const useBillwerkStore = defineStore('billwerk', () => {
	const paymentService = ref<BillwerkPaymentService>();
	const allPaymentMethods = ref<string[]>([]);
	const paymentMethod = ref<string | null>(null);
	const secretPaymentData = ref<SecretPaymentData>(null);
	const customerChange = ref<CustomerChange | null>(null);
	const finalizedOrder = ref<FinalizeData | null>(null);
	const order = ref<KeysToPascalCase<Order> | null>(null);

	const userStore = useUserStore();
	const productsStore = useProductsStore();
	const router = useRouter();
	const { locale } = useI18n();

	const { load: initBillwerk } = useScriptTag(`${import.meta.env.VITE_BILLWERK_SELFSERVICE_URL}/subscription.js`, () => {
		paymentService.value = new window.SubscriptionJS.Payment(
			{
				publicApiKey: import.meta.env.VITE_BILLWERK_PUBLIC_KEY,
				providerReturnUrl: window.origin + router.resolve({ name: 'finalizePayment', params: { lang: locale.value } }).href,
			},
			async () => {
				allPaymentMethods.value = paymentService.value?.getAvailablePaymentMethodEnum() ?? [];
			},
			() => {},
		);
	});

	const availablePaymentMethods = computed(() => paymentMethods
		.filter((_paymentMethod) => allPaymentMethods.value.includes(_paymentMethod.ID) && _paymentMethod.languages.includes(locale.value as SupportedLocale)));

	const selectedPaymentMethod = computed(() => availablePaymentMethods.value.find((_paymentMethod) => _paymentMethod.ID === paymentMethod.value));

	const cartStore = useCartStore();

	const internalOrder = computed<InternalOrder | null>(() => {
		if (!userStore.billwerkCustomerId || !cartStore.cart) {
			return null;
		}

		return {
			M2MCouponCode: cartStore.cart?.M2MCouponCode ?? null,
			CustomerId: userStore.billwerkCustomerId,
			CustomerChange: customerChange.value,
			SkipTrial: !productsStore.allowsTrialOrder,
			Cart: {
				PlanVariantID: cartStore.cart.planVariant.Id,
				DiscountSubscriptions: cartStore.cart.DiscountSubscriptions ?? [],
				CouponCode: cartStore.cart.CouponCode ?? null,
			},
		};
	});

	const createOrder = async () => {
		try {
			const response = await contentApiClient.client
				.post('user/createOrder', {
					json: internalOrder.value,
				})
				.json<Order>();

			const normalizedOrder = capitalizeObjectKeys(response);
			order.value = normalizedOrder;

			return okAsync(normalizedOrder);
		} catch (error) {
			logger.error(error);
			return errAsync(new AppError(AppErrorCodes.CREATE_ORDER_FAILED, error));
		}
	};

	const paymentInformation = computed(() => ({
		secretPaymentData: secretPaymentData.value,
		order: order.value,
		paymentMethod: selectedPaymentMethod.value?.ID,
	}));

	const finalize: SubscriptionJSFinalize = (onSuccess, onError) => initBillwerk()
		.then(() => window.SubscriptionJS.finalize(onSuccess, onError))
		.catch(onError);

	return {
		initBillwerk,
		paymentService,
		availablePaymentMethods,
		paymentMethod,
		selectedPaymentMethod,
		secretPaymentData,
		customerChange,
		finalizedOrder,
		createOrder,
		internalOrder,
		paymentInformation,
		finalize,
	};
});
