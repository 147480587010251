/* This is a manual copy from https://github.com/mathieustan/vue-intercom */

/* eslint-disable camelcase */
import { EventEmitter } from 'events';
import { loadAsyncScript } from '@/utilities/Helpers';

export interface IntercomMessengerAttributes {
	app_id: string;
	custom_launcher_selector: string;
	alignment: string;
	vertical_padding: number;
	horizontal_padding: number;
	hide_default_launcher: boolean;
	session_duration: number;
	action_color: string;
	background_color: string;
}

export interface IntercomDataAttributes {
	email: string;
	user_id: string;
	created_at: string;
	name: string;
	phone: string;
	last_request_at: string;
	unsubscribed_from_emails: boolean;
	language_override: string;
	utm_campaign: string;
	utm_content: string;
	utm_medium: string;
	utm_source: string;
	utm_term: string;
	/* eslint-disable-next-line */
	avatar: any;
	user_hash: string;
	/* eslint-disable-next-line */
	company: any;
	/* eslint-disable-next-line */
	companies: any[];
	beta: boolean;
}

export type IntercomCallOptions = IntercomMessengerAttributes & IntercomDataAttributes;

export type IntercomOptions = Pick<IntercomMessengerAttributes, 'app_id'> & Partial<IntercomMessengerAttributes>;

export default class Intercom extends EventEmitter {
	appId: string;
	defaultOptions: Partial<IntercomMessengerAttributes>;
	ready: boolean;
	isBooted: boolean;
	visible: boolean;
	unreadCount: number;

	constructor(options: IntercomOptions) {
		super();

		this.appId = options.app_id;
		this.defaultOptions = options;
		this.ready = false;
		this.isBooted = false;
		this.visible = false;
		this.unreadCount = 0;

		this.load();
	}

	// Load intercom script with defer
	load() {
		if (!window || !document) {
			return;
		}

		const load = () => loadAsyncScript(`https://widget.intercom.io/widget/${this.appId}`, () => this.init());

		if (document.readyState === 'complete') {
			loadAsyncScript(`https://widget.intercom.io/widget/${this.appId}`, () => this.init());
			return;
		}

		window.addEventListener('load', load, false);
	}

	// Init Intercom service
	init() {
		this.ready = true;
		this.callIntercom('onHide', () => { this.visible = false; });
		this.callIntercom('onShow', () => { this.visible = true; });
		this.callIntercom('onUnreadCountChange', (unreadCount: number) => { this.unreadCount = unreadCount; });

		this.emit('ready');
	}

	// Boot Intercom service with user
	boot(options?: Partial<IntercomCallOptions>) {
		this.callIntercom('boot', { ...this.defaultOptions, ...options });
		this.isBooted = true;
	}

	/* eslint-disable-next-line class-methods-use-this */
	/* eslint-disable-next-line */
	callIntercom(...args: any[]) {
		if (!window.Intercom) {
			this.once('ready', () => { this.callIntercom(...args); });
			return;
		}

		window.Intercom(...args);
	}

	shutdown() {
		this.isBooted = false;
		this.callIntercom('shutdown');
	}

	/* eslint-disable-next-line */
	update(...options: any[]) { this.callIntercom('update', ...options); }

	updateDefaults(options: Partial<IntercomMessengerAttributes>) {
		this.defaultOptions = { ...this.defaultOptions, ...options };
		this.callIntercom('update', options);
	}

	show() { this.callIntercom('show'); }

	hide() { this.callIntercom('hide'); }

	/* eslint-disable-next-line class-methods-use-this */
	hideIcon() {
		const element = (document.querySelector('.intercom-lightweight-app') || document.querySelector('#intercom-container')) as HTMLElement;

		if (!element) {
			return;
		}

		element.style.display = 'none';
	}

	/* eslint-disable-next-line class-methods-use-this */
	showIcon() {
		const element = (document.querySelector('.intercom-lightweight-app') || document.querySelector('#intercom-container')) as HTMLElement;

		if (!element) {
			return;
		}

		element.style.display = '';
	}

	showMessages() { this.callIntercom('showMessages'); }

	showNewMessage(content: string) {
		this.callIntercom('showNewMessage', [content]);
	}

	trackEvent(name: string, ...metadata: string[]) { this.callIntercom('trackEvent', ...[name, ...metadata]); }

	startTour(id: string, ...metadata: string[]) { this.callIntercom('startTour', ...[id, ...metadata]); }

	getVisitorId() { this.callIntercom('getVisitorId'); }
}
