import { createClient } from '@supabase/supabase-js';
import { useRouter } from 'vue-router';
import { resolveURL } from 'ufo';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
	auth: {
		persistSession: false,
	},
});

export const useSupabaseSignIn = () => {
	const router = useRouter();

	const createRedirectUrl = (redirectTo?: string) => resolveURL(window.location.origin, router.resolve({ name: 'SocialCallback', query: { redirectTo } }).fullPath);

	const signInWithGoogle = async (redirectTo?: string) => {
		supabase.auth.signInWithOAuth({
			provider: 'google',
			options: {
				redirectTo: createRedirectUrl(redirectTo),
			},
		});
	};

	const signInWithDiscord = async (redirectTo?: string) => {
		supabase.auth.signInWithOAuth({
			provider: 'discord',
			options: {
				redirectTo: createRedirectUrl(redirectTo),
			},
		});
	};

	const signInWithFacebook = async (redirectTo?: string) => {
		supabase.auth.signInWithOAuth({
			provider: 'facebook',
			options: {
				redirectTo: createRedirectUrl(redirectTo),
			},
		});
	};

	const signInWithApple = async (redirectTo?: string) => {
		supabase.auth.signInWithOAuth({
			provider: 'apple',
			options: {
				redirectTo: createRedirectUrl(redirectTo),
			},
		});
	};

	return {
		signInWithGoogle,
		signInWithDiscord,
		signInWithFacebook,
		signInWithApple,
	};
};
