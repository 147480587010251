import { sentryTransactionHook } from '@/composables/useCustomSentryTransaction';
import { APIClient } from '.';

export const oauthClient = new APIClient({
	prefixUrl: import.meta.env.VITE_CONTENT_API_URL,
	headers: {
		'api-version': '3.0',
	},
	hooks: {
		beforeRequest: [
			sentryTransactionHook,
		],
	},
});
