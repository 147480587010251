<script lang="ts" setup>
import LoaderWithCheckmark from '@/components/LoaderWithCheckmark.vue';
import { supabase } from '@/plugins/supabase';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { useI18n } from 'vue-i18n';
import { getUserCountryCode } from '@/utilities/UserIPInfo';
import { useSignupTracking } from '@/composables/useSignupTracking';
import { useIntercom } from '@/plugins/intercom/useIntercom';
import TransitionExpand from '@/components/TransitionExpand.vue';
import * as Sentry from '@sentry/vue';
import { useToast } from '@sunlabde/m2m-library';

const router = useRouter();

const route = useRoute();

const authStore = useAuthStore();

const isSuccess = ref(false);

const { t, locale } = useI18n();

const { trackNewSignup } = useSignupTracking();

const intercom = useIntercom();

const toast = useToast();

onMounted(async () => {
	const session = await supabase.auth.getSession();

	if (!session.data.session) {
		Sentry.withScope((scope) => {
			scope.setLevel('error');
			scope.setTransactionName('Social login failed because of an unexpected error.');
			const eventId = Sentry.captureException(session.error);
			toast.error(t('notification.error.signup.withErrorCode.text', [eventId]), { timeout: false });
		});

		router.push({ name: 'signup' });
		return;
	}

	const countryCode = await getUserCountryCode() ?? undefined;

	const loginResult = await authStore.loginUserWithSupabase({
		token: session.data.session.access_token,
		locale: locale.value,
		countryCode,
	});

	if (loginResult.isErr()) {
		router.push({ name: 'signup' });
		return;
	}

	isSuccess.value = true;

	const { user: authUser, accountCreated } = loginResult.value;

	if (accountCreated) {
		trackNewSignup();
	}

	intercom.client.update({
		user_id: authUser.profileId,
		user_hash: authUser.intercomHash,
		name: `${authUser.firstname} ${authUser.lastname}`,
		email: authUser.email,
		beta: true,
	});

	router.push(route.query.redirectTo as string || { name: 'subscribe' });
});

</script>

<template>
	<div class="flex items-center justify-center flex-col h-screen max-w-xl mx-auto text-center">
		<LoaderWithCheckmark
			class="mb-4"
			:is-success="isSuccess"
		/>
		<TransitionExpand mode="out-in">
			<p v-if="isSuccess">
				{{ $t('view.socialCallback.title.pending') }}
			</p>
			<p v-else>
				{{ $t('view.socialCallback.title.redirect') }}
			</p>
		</TransitionExpand>
	</div>
</template>
