import { useSupabaseSignIn } from '@/plugins/supabase';
import { computed } from 'vue';
import GoogleLogo from '@/assets/google_logo.svg';
import FacebookLogo from '@/assets/facebook_logo.svg';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import { useI18n } from 'vue-i18n';
import { TranslationString } from '@/models/Language';
import AppleLogo from '@/assets/apple_logo.svg';
import DiscordLogo from '@/assets/discord_logo.svg';

export type SocialLoginButton = {
	name: string;
	isEnabled: boolean;
	logo: string;
	label: TranslationString;
	onClick: (redirectUrl?: string) => Promise<void>;
}

export const useLoginWithSocialProviderButtons = () => {
	const {
		signInWithGoogle,
		signInWithDiscord,
		signInWithApple,
		signInWithFacebook,
	} = useSupabaseSignIn();

	const { t } = useI18n();

	const buttons = computed<SocialLoginButton[]>(() => {
		const socialProvider: Record<string, Omit<SocialLoginButton, 'name'>> = {
			google: {
				onClick: signInWithGoogle,
				logo: GoogleLogo,
				label: t('button.continueWithGoogle'),
				isEnabled: isFeatureFlagEnabled(FeatureFlag.SOCIAL_PROVIDER_GOOGLE),
			},
			discord: {
				onClick: signInWithDiscord,
				logo: DiscordLogo,
				label: t('button.continueWithDiscord'),
				isEnabled: isFeatureFlagEnabled(FeatureFlag.SOCIAL_PROVIDER_DISCORD),
			},
			facebook: {
				onClick: signInWithFacebook,
				logo: FacebookLogo,
				label: t('button.continueWithFacebook'),
				isEnabled: isFeatureFlagEnabled(FeatureFlag.SOCIAL_PROVIDER_FACEBOOK),
			},
			apple: {
				onClick: signInWithApple,
				logo: AppleLogo,
				label: t('button.continueWithApple'),
				isEnabled: isFeatureFlagEnabled(FeatureFlag.SOCIAL_PROVIDER_APPLE),
			},
		};

		const activeSocialProvider = Object.entries(socialProvider)
			.filter(([, { isEnabled }]) => isEnabled)
			.map(([name, props]) => ({ name, ...props }));

		return activeSocialProvider;
	});

	const isAnySocialLoginProviderEnabled = computed(() => buttons.value.length > 0);

	return {
		isAnySocialLoginProviderEnabled,
		buttons,
	};
};
