import { defineStore } from 'pinia';
import { Cart } from '@/models/Cart';
import { Product } from '@/models/Product';
import { Discount, DiscountWithCode } from '@/models/Discounts';
import { DiscountSubscription } from '@/models/DiscountSubscription';
import { DateTime } from 'luxon';
import logger from '@/plugins/Logger';
import { computed, ref } from 'vue';

export interface CartState {
	cart: Cart | null;
}

export const useCartStore = defineStore('cart', () => {
	const cart = ref<Cart | null>(null);

	const hasDiscountApplied = computed(() => (cart.value ? (Array.isArray(cart.value.DiscountSubscriptions) && cart.value.DiscountSubscriptions.length > 0) : false));

	const appliedDiscount = computed<Discount | null>(() => cart.value?.discount ?? null);

	const clearCart = () => {
		cart.value = null;
	};

	const setCartFromProduct = (product: Product, endOfTrialDate?: DateTime) => {
		if (product.discount) {
			const discountSubscriptions: DiscountSubscription[] = [
				{
					DiscountId: product.discount.Id,
					StartDate: endOfTrialDate?.toISO() ?? undefined,
				},
			];
			cart.value = { ...product, DiscountSubscriptions: discountSubscriptions };
			return;
		}

		cart.value = product;
	};

	const addDiscount = (discount: DiscountWithCode, endOfTrialDate?: DateTime) => {
		if (!cart.value) {
			return;
		}

		try {
			const newCart = { ...cart.value, CouponCode: discount.code, discount };
			const discountSubscription: DiscountSubscription = { DiscountId: discount.Id };

			const endOfTrialIsoDate = endOfTrialDate?.toISODate();

			if (endOfTrialIsoDate) {
				discountSubscription.StartDate = endOfTrialIsoDate;
			}

			if (Array.isArray(newCart.DiscountSubscriptions)) {
				newCart.DiscountSubscriptions.push(discountSubscription);
			} else {
				newCart.DiscountSubscriptions = [discountSubscription];
			}

			cart.value = newCart;
		} catch (error) {
			logger.error(error);
		}
	};

	const removeAllDiscounts = () => {
		if (!cart.value) {
			return;
		}

		const newCart = { ...cart.value };

		delete newCart.CouponCode;
		delete newCart.discount;
		delete newCart.DiscountSubscriptions;

		cart.value = newCart;
	};

	const themeFromSelectedPlan = computed(() => {
		if (!cart.value?.planVariant) {
			return 'combo';
		}

		if (!cart.value.planVariant.CustomFields.theme) {
			return cart.value.planVariant.CustomFields.category;
		}

		return cart.value.planVariant.CustomFields.theme;
	});

	return {
		cart,
		hasDiscountApplied,
		appliedDiscount,
		themeFromSelectedPlan,
		clearCart,
		setCartFromProduct,
		addDiscount,
		removeAllDiscounts,
	};
});
