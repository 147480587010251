<template>
	<transition
		name="modal"
		appear
		@after-leave="afterLeave"
	>
		<div
			v-show="showModal"
			:class="classObject"
			class="modal modal--boxed flex items-center justify-center fixed top-0 right-0 bottom-0 left-0 z-[200] px-8"
			data-cy="modal"
		>
			<div class="modal-box relative z-[2] flex flex-col items-center justify-center bg-white p-8 rounded-lg max-w-[560px] w-full min-h-[200px]">
				<div
					v-if="hasHeaderSlot"
					class="mb-8"
				>
					<slot name="header" />
				</div>
				<h2
					class="modal-title text-[24px] font-bold mb-4"
					data-cy="modal_title"
				>
					<slot name="title" />
				</h2>
				<div class="modal-body">
					<slot name="body" />
				</div>
				<div
					v-if="hasFooterSlot"
					class="flex justify-center text-center mt-8"
				>
					<slot
						:close="closeModal"
						name="footer"
					/>
				</div>
			</div>
			<div
				class="absolute top-0 right-0 left-0 bottom-0 bg-black opacity-70 z-[1]"
				@click="allowBackdropClose ? closeModal() : ''"
			/>
		</div>
	</transition>
</template>

<script setup lang="ts">
import { computed, ref, useSlots } from 'vue';

const props = defineProps({
	backgroundPath: {
		type: String,
		required: false,
		default: '',
	},
	allowBackdropClose: {
		type: Boolean,
		required: false,
		default: true,
	},
	contentAlign: {
		type: String,
		required: false,
		default: 'left',
		validator: (value: string) => ['left', 'center'].indexOf(value) !== -1,
	},
});

const emit = defineEmits(['close', 'afterClose']);

const slot = useSlots();
const showModal = ref(true);

const hasHeaderSlot = computed(() => !!slot.header);

const hasFooterSlot = computed(() => !!slot.footer);

const classObject = computed(() => ({
	'text-center': props.contentAlign === 'center',
}));

const closeModal = () => {
	showModal.value = false;
	emit('close');
};

const afterLeave = () => emit('afterClose');
</script>

<style lang="scss" scoped>
.modal {
	&.modal--boxed {
		.modal-box {
			animation: boxed-in 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
		}
	}

	&.modal--seemless {
		.modal-title {
			@apply text-white text-center w-8/12 mx-auto mb-8;
		}

		.modal-body {
			@apply max-w-[856px];
		}
	}

	&.modal-enter-active,
	&.modal-leave-active {
		@apply transition duration-375 ease-smooth;
	}

	&.modal-enter,
	&.modal-leave-to {
		@apply opacity-0;
	}
}

@keyframes boxed-in {
	0% {
		@apply transform scale-75 opacity-0;
	}

	100% {
		@apply transform scale-100 opacity-100;
	}
}
</style>
