export type BillingOccurence = 'Monthly' | 'Trimonthly' | 'Biannually' | 'Yearly';

export interface BillingOccurenceDescription {
	id: BillingOccurence;
	name: string;
	unit: string;
	months: number;
}

export const billingOccurences: Record<BillingOccurence, BillingOccurenceDescription> = {
	Monthly: {
		id: 'Monthly',
		name: 'billingOccurence.month.name',
		unit: 'billingOccurence.month.unit',
		months: 1,
	},
	Trimonthly: {
		id: 'Trimonthly',
		name: 'billingOccurence.trimonth.name',
		unit: 'billingOccurence.trimonth.unit',
		months: 3,
	},
	Biannually: {
		id: 'Biannually',
		name: 'billingOccurence.biannual.name',
		unit: 'billingOccurence.biannual.unit',
		months: 6,
	},
	Yearly: {
		id: 'Yearly',
		name: 'billingOccurence.annual.name',
		unit: 'billingOccurence.annual.unit',
		months: 12,
	},
};

export const getBillingOccurenceByMonths = (months: number): BillingOccurence | null => {
	const billingOccurence = Object.values(billingOccurences).find((description) => description.months === months);

	if (!billingOccurence) {
		return null;
	}

	return billingOccurence.id;
};
