<template>
	<div class="breadcrumbs flex items-center justify-center">
		<div
			v-for="(step, index) in stepBundle"
			:key="step"
			:class="classObject(step)"
			@click="goToStep(index)"
		>
			<div
				v-if="currentStepIndex > stepBundle.indexOf(step)"
				class="item-index h-6 w-6 border border-solid border-gray-500 rounded-[50%] flex items-center justify-center text-2xs font-black text-gray-500 transition duration-500 ease-smooth"
			>
				<FontAwesomeIcon icon="check" />
			</div>
			<div
				v-else
				class="item-index h-6 w-6 border border-solid border-gray-500 rounded-[50%] flex items-center justify-center text-2xs font-black text-gray-500 transition duration-500 ease-smooth"
			>
				{{ index + 1 }}
			</div>
			<div class="item-label text-xs text-gray-500 mt-1 transition duration-500 ease-smooth">
				{{ localize(getStepName(step)) }}
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { getSignupTypeMeta, SignupType } from '@/models/SignupType';
import { computed } from 'vue';
import { SignupStep, getStepName } from '@/models/SignupStep';
import { useRouter } from 'vue-router';
import { localize } from '@/models/Language';
import { useSubscribeStore } from '@/store/subscribe';
import { useMainStore } from '@/store/main';
import { FontAwesomeIcon } from '@sunlabde/m2m-library';

const mainStore = useMainStore();
const subscribeStore = useSubscribeStore();
const currentStepIndex = computed<number>(() => subscribeStore.currentStepIndex);
const currentSignupType = computed<SignupType>(() => mainStore.signupType);
const stepBundle = computed(() => getSignupTypeMeta(currentSignupType.value).stepBundle);

const classObject = (step: SignupStep) => ({
	'breadcrumbs-item w-[110px] flex items-center flex-nowrap flex-col relative pointer-events-none': true,
	'is-current': currentStepIndex.value === stepBundle.value.indexOf(step),
	'is-done': currentStepIndex.value > stepBundle.value.indexOf(step),
});

const router = useRouter();

const goToStep = (stepIndex: number) => {
	router.push({
		name: 'subscribe',
		query: {
			...router.currentRoute.value.query,
			step: stepIndex + 1,
		},
	});
};
</script>

<style lang="scss" scoped>
.breadcrumbs {

	.breadcrumbs-item {

		&:not(:first-child) {
			@apply after:block after:absolute after:h-[1px] after:border-t-4 after:border-dotted after:border-gray-300 after:w-[calc(100%-42px)] after:right-[calc(50%+21px)] after:top-[13px] after:transition after:duration-500 after:ease-smooth;
		}

		&.is-current {
			.item-index {
				@apply bg-secondary-500 border-secondary-500 text-white;
			}

			.item-label {
				@apply text-secondary-500;
			}
		}

		&.is-done {
			pointer-events: all;
			@apply cursor-pointer;

			.item-index {
				@apply bg-green-300 border-green-300 text-white;
			}
			.item-label {
				@apply text-green-300;
			}
		}
	}
}
</style>
