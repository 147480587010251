/* eslint-disable max-classes-per-file */

class CouponError extends Error {
	constructor(error: string) {
		super(`coupon_error.${error}`);
		this.name = 'CouponError';
		this.message = `coupon_error.${error}`;

		if (import.meta.env.MODE === 'development') {
			console.error('[Coupon Error]', error);
		}
	}
}

class DiscountError extends Error {
	constructor(error: string) {
		super(`discount_error.${error}`);
		this.name = 'DiscountError';
		this.message = `discount_error.${error}`;

		if (import.meta.env.MODE === 'development') {
			console.error('[Discount Error]', error);
		}
	}
}

class FormError extends Error {
	constructor(error: string) {
		super(`coupon_error.${error}`);
		this.name = 'FormError';
		this.message = `form_error.${error}`;

		if (import.meta.env.MODE === 'development') {
			console.error('[Form Error]', error);
		}
	}
}

export {
	CouponError, FormError, DiscountError,
};
