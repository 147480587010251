import { getPortalCarts } from '@/api/portal';
import { SignupPortal, portalAllowsTrialOrder } from '@/models/SignupPortal';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getPlanVariantId, getPlanVariantIds } from '@/models/PortalCart';
import { getPlanVariantsById } from '@/api/planvariants';
import { useLanguage } from '@/composables/useLanguage';
import { getComboComparisonByBillingOccurence, getPlanVariantsForLanguage } from '@/models/PlanVariant';
import { Product } from '@/models/Product';
import { lookupDiscountCodeInCarts } from '@/api/discount';
import { InternalOrderCart } from '@/models/InternalOrder';
import { useAuthStore } from './auth';
import { useUserStore } from './user';

export const useProductsStore = defineStore('products', () => {
	const authStore = useAuthStore();
	const userStore = useUserStore();

	const portal = ref<SignupPortal>();

	const portalId = computed(() => portal.value?.name ?? 'default');

	const { data: portalCategoryCarts, isFetched } = useQuery(['portal', portalId], async () => {
		const response = await getPortalCarts({
			portalId: portalId.value,
			countryCode: authStore.user?.country ?? 'de',
		}).unwrapOr(null);

		return response;
	});

	const planVariantsToFetch = computed(() => (portalCategoryCarts.value ? getPlanVariantIds(portalCategoryCarts.value) : []));

	const { data: planVariantsData } = useQuery(
		['planVariants', planVariantsToFetch],
		async () => {
			const result = await getPlanVariantsById(planVariantsToFetch.value).unwrapOr([]);

			const { selectedLanguage } = useLanguage();

			const planVariantsForDisplayLanguage = getPlanVariantsForLanguage(result, selectedLanguage.value.code);

			return planVariantsForDisplayLanguage;
		},
		{
			initialData: [],
			enabled: computed(() => planVariantsToFetch.value.length > 0),
			refetchOnWindowFocus: false,
		},
	);

	const internalCartsWithDiscounts = computed(() => {
		if (!portalCategoryCarts.value) {
			return [];
		}

		return Object.entries(portalCategoryCarts.value)
			.map(([, portalCarts]) => portalCarts)
			.flat()
			.map((portalCart) => {
				const planVariantId = getPlanVariantId(portalCart);
				const planVariant = planVariantsData.value.find((p) => p.Id === planVariantId);

				if (!planVariant) {
					return null;
				}

				const discountCode = portal.value && portal.value.options.discountCode ? portal.value.options.discountCode : portalCart.billwerkCouponCode;

				if (!discountCode) {
					return null;
				}

				const cart: InternalOrderCart = { PlanVariantID: planVariantId, CouponCode: discountCode, DiscountSubscriptions: [] };

				return cart;
			})
			.filter((cart): cart is InternalOrderCart => cart !== null);
	});

	const { data: discountsData } = useQuery(
		['discounts', internalCartsWithDiscounts],
		async () => {
			if (!userStore.billwerkCustomerId) {
				return [];
			}

			const result = (await lookupDiscountCodeInCarts(
				userStore.billwerkCustomerId,
				internalCartsWithDiscounts.value,
			)).unwrapOr([]);

			return result;
		},
		{
			initialData: [],
			enabled: computed(() => internalCartsWithDiscounts.value.length > 0),
			refetchOnWindowFocus: false,
		},
	);

	const products = computed<Product[]>(() => {
		if (!portalCategoryCarts.value) {
			return [];
		}

		return Object.entries(portalCategoryCarts.value)
			.map(([, portalCarts]) => portalCarts)
			.flat()
			.map((portalCart) => {
				const planVariantId = getPlanVariantId(portalCart);
				const planVariant = planVariantsData.value.find((p) => p.Id === planVariantId);

				if (!planVariant) {
					return null;
				}

				const discountItem = discountsData.value.find((item) => !!item.Targets[planVariant.Id]);

				const product: Product = {
					planVariant,
					discount: discountItem,
				};

				return product;
			})
			.filter((product): product is Product => product !== null);
	});

	const getProductsFromCategory = (category: string): Product[] => products.value
		.filter((product) => product.planVariant.CustomFields.category === category);

	const comboComparisonByBillingOccurence = computed(() => getComboComparisonByBillingOccurence(planVariantsData.value));

	const allowsTrialOrder = computed(() => (userStore.qualifiesForTrial ? portalAllowsTrialOrder(portal.value?.name ?? 'default') : false));

	return {
		portal,
		carts: products,
		data: portalCategoryCarts,
		planVariantsToFetch,
		planVariantsData,
		getProductsFromCategory,
		comboComparisonByBillingOccurence,
		allowsTrialOrder,
		discountsData,
		isFetched,
	};
});
