import { defineStore } from 'pinia';
import { ref } from 'vue';
import { CustomErrorModal } from '@/models/CustomErrorModal';

export const useCustomErrorModal = defineStore('customErrorModal', () => {
	const customErrorModal = ref<CustomErrorModal | null>(null);

	const setCustomErrorModal = (modal: CustomErrorModal) => {
		customErrorModal.value = modal;
	};
	const clearCustomErrorModal = () => {
		customErrorModal.value = null;
	};

	return {
		customErrorModal,
		setCustomErrorModal,
		clearCustomErrorModal,
	};
});
