import i18n from '@/plugins/i18n';
import { TranslationString } from './Language';

export type ExperienceLevel = 'beginner' | 'normal' | 'expert';

export type ExperienceLevelMeta<T extends ExperienceLevel = ExperienceLevel> = {
	id: T;
	name: TranslationString;
}

export const experienceLevels: { [K in ExperienceLevel]: ExperienceLevelMeta<K> } = {
	beginner: {
		id: 'beginner',
		name: () => i18n.global.t('subscribe.step.course.experienceLevel.1'),
	},
	normal: {
		id: 'normal',
		name: () => i18n.global.t('subscribe.step.course.experienceLevel.2'),
	},
	expert: {
		id: 'expert',
		name: () => i18n.global.t('subscribe.step.course.experienceLevel.3'),
	},
};
