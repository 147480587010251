import { createI18n, I18n } from 'vue-i18n';
import { setLocale } from '@vee-validate/i18n';
import de from '@/locales/de.json';
import en from '@/locales/en.json';
import es from '@/locales/es.json';

export const setI18nLanguage = (i18n: I18n, locale: string) => {
	if (i18n.mode === 'legacy') {
		i18n.global.locale = locale;
	} else {
		/* eslint-disable @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		i18n.global.locale.value = locale;
	}

	setLocale(locale);
};

export const setupI18n = (options = { locale: import.meta.env.VITE_I18N_FALLBACK_LOCALE }) => {
	const i18n = createI18n({
		...options,
		messages: {
			de,
			en,
			es,
		},
	}) as I18n;

	if (options.locale) {
		setI18nLanguage(i18n, options.locale);
	}

	return i18n;
};

const i18n = setupI18n();

export default i18n;
