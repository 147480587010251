import { LocationQueryValue, RouteLocationNormalized } from 'vue-router';
import { isLocaleSupported } from '@/models/Language';
import i18n from '@/plugins/i18n';
import { isFeatureFlagURLParam } from '@/models/FeatureFlag';

export const getLocaleFromRouteOrStorage = (route: RouteLocationNormalized) => {
	let locale = localStorage.getItem('language') || navigator.language;

	if (route.params.lang) {
		locale = Array.isArray(route.params.lang) ? route.params.lang[0] : route.params.lang;
	}

	if (!isLocaleSupported(locale)) {
		locale = import.meta.env.VITE_I18N_FALLBACK_LOCALE;
	}

	return locale;
};

export const requiresValidLocale = (route: RouteLocationNormalized) => !route.matched.some((r) => r.meta.skipLocaleCheck);

export const hasRouteValidLocale = (route: RouteLocationNormalized) => {
	if (!route.params.lang) {
		return false;
	}

	if (route.params.lang !== i18n.global.locale) {
		return false;
	}

	const languageFromRoute = Array.isArray(route.params.lang) ? route.params.lang[0] : route.params.lang;

	if (!isLocaleSupported(languageFromRoute)) {
		return false;
	}

	return i18n.global.availableLocales.includes(languageFromRoute);
};

export const getFeatureFlagQuery = (route: RouteLocationNormalized) => Object.entries(route.query)
	.filter(([key]) => isFeatureFlagURLParam(key))
	.reduce((query, [key, value]) => ({
		...query,
		[key]: value,
	}), {});

export const createRedirectToQuery = (route: RouteLocationNormalized) => {
	const featureFlags = getFeatureFlagQuery(route);

	return {
		...featureFlags,
		// TODO: Not use fullPath because of potential "lang"-param mismatch
		redirectTo: route.fullPath,
	};
};

export const getLocationQueryValue = (route: RouteLocationNormalized, queryName: string): LocationQueryValue => {
	const queryValue = route.query[queryName];

	if (Array.isArray(queryValue)) {
		return queryValue[0];
	}

	return queryValue;
};

export const isCouponRoute = (route: RouteLocationNormalized): boolean => route.query.type === 'coupon';
