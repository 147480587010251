import { HTTPError } from 'ky';
import { ResultAsync } from 'neverthrow';
import { StatusCodes } from 'http-status-codes';
import { responseWithErrorHandler } from '@/api';
import AppError from '@/models/AppError';
import { AppErrorCodes, AuthErrorCodes } from '@/enums/ErrorCodes';
import { UserResponse } from '@/models/UserResponse';
import { TokenResponse, TokenResponseRaw, toTokenResponse } from '@/models/TokenResponse';
import { SupabaseLoginOptions } from '@/models/Auth';
import { oauthClient as api } from './oauthClient';

export const useOAuthApi = () => {
	const loginUserWithPassword = (username: string, password: string): ResultAsync<TokenResponse, Error> => {
		const data: Promise<TokenResponseRaw> = api.client.post('oauth/token', {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: `client_id=${import.meta.env.VITE_API_CLIENT_ID}&grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
		}).json();

		return responseWithErrorHandler(data).map(toTokenResponse);
	};

	const loginWithSupabaseToken = (options: SupabaseLoginOptions): ResultAsync<TokenResponse, Error> => {
		let body = `client_id=${import.meta.env.VITE_API_CLIENT_ID}&grant_type=social&ui_locales=${options.locale}`;

		if (options.countryCode) {
			body += `&country_code=${options.countryCode}`;
		}

		const data: Promise<TokenResponseRaw> = api.client.post('oauth/social', {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: `Bearer ${options.token}`,
			},
			body,
		}).json();

		return responseWithErrorHandler(data).map(toTokenResponse);
	};

	const getCurrentUserInfo = (accessToken: string): ResultAsync<UserResponse, AppError> => {
		const data: Promise<UserResponse> = api.client.get('oauth/user', {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}).json();

		return responseWithErrorHandler(data);
	};

	const refreshAccessToken = (refreshToken: string): ResultAsync<TokenResponse, AppError> => {
		const onReject = (error: unknown) => {
			if (!(error instanceof HTTPError)) return new AppError(AppErrorCodes.GENERIC, error);

			if (error.response.status === StatusCodes.UNAUTHORIZED) {
				return new AppError(AuthErrorCodes.TOKEN_UNAUTHORIZED, error);
			}

			if (error.response.status === StatusCodes.BAD_REQUEST) {
				return new AppError(AuthErrorCodes.TOKEN_INVALID, error);
			}

			return new AppError(AuthErrorCodes.GENERIC, error);
		};

		const data = api.client.post('oauth/token', {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: `client_id=${import.meta.env.VITE_API_CLIENT_ID}&grant_type=refresh_token&refresh_token=${refreshToken}`,
		}).json() as Promise<TokenResponseRaw>;

		return ResultAsync.fromPromise(data, onReject).map((tokenResponseRaw: TokenResponseRaw): TokenResponse => {
			const expiryDate = new Date();

			expiryDate.setSeconds((expiryDate.getSeconds() + tokenResponseRaw.expires_in) - 60);

			return {
				...tokenResponseRaw,
				account_created: false,
				expires_at: expiryDate,
			};
		});
	};

	const revokeToken = (token: string) => {
		const onReject = (error: unknown) => {
			if (!(error instanceof HTTPError)) return new Error();

			return new AppError(AuthErrorCodes.GENERIC, error);
		};

		const data = api.client.post('oauth/revoke', {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: `token=${token}`,
		}).json() as Promise<TokenResponse>;

		return ResultAsync.fromPromise(data, onReject);
	};

	const revokeAccessToken = (accessToken: string) => revokeToken(accessToken);

	return {
		loginUserWithPassword,
		loginWithSupabaseToken,
		getCurrentUserInfo,
		refreshAccessToken,
		revokeAccessToken,
	};
};
