export type SelectOption<T extends string = string> =
	| T
	| {
		value: T;
		label: string;
	};

export const isPrimitiveOption = <T extends string = string>(
	option: SelectOption<T>,
): option is T => ['string'].includes(typeof option);

export const getOptionValue = <T extends string = string>(option: SelectOption<T>): T => {
	if (isPrimitiveOption(option)) {
		return option;
	}

	return option.value;
};

export const getOptionLabel = (option: SelectOption): string => {
	if (isPrimitiveOption(option)) {
		return option;
	}

	return `${option.label}`;
};
