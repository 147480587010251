/**
 * IMPORTANT NOTE: Copied from Webapp Repository.
 * You should not make changes to this file without
 * modifyingit in the Webapp Respository as well.
 * This file should be part of a shared library.
 */

import { ISOCurrency } from './Currency';
import { PaymentMethodInformation } from './PaymentMethod';
import { SubscriptionDiscountRaw } from './SubscriptionDiscount';
import { SubscriptionProvider } from './SubscriptionProvider';

export interface BaseSubscriptionRaw {
	provider: SubscriptionProvider;
	isTrial: boolean;
	displayName?: string;
	displayNames?: Record<string, string>;
	billingPeriod?: string;
	pricePerOccurrence?: number;
	currency?: ISOCurrency;
	discounts?: SubscriptionDiscountRaw[];
	startAt?: string;
	lastBillingAt?: string;
	nextBillingAt?: string;
	expiresAt?: string;
	paymentMethod?: PaymentMethodInformation;
	// TODO: CHANGED – Typo, sync with webapp repository
	privileges?: string[];
	// TODO: NEW – Sync with webapp repository
	status?: string;
}

export interface BillwerkSubscriptionRaw extends BaseSubscriptionRaw {
	billwerkContractId?: string;
	selfServiceLink?: string;
}

export interface LegacySubscriptionRaw extends BaseSubscriptionRaw {
	legacySubscriptionID?: string;
}

export type UserSubscriptionRaw = BillwerkSubscriptionRaw | LegacySubscriptionRaw;

export interface UserSubscriptionInformationRaw {
	billwerkCustomerId: string;
	qualifiesForTrial: boolean;
	subscriptions: UserSubscriptionRaw[];
}

export const isBillwerkSubscriptionRaw = (subscriptionRaw: UserSubscriptionRaw): subscriptionRaw is BillwerkSubscriptionRaw => typeof (subscriptionRaw as BillwerkSubscriptionRaw).billwerkContractId === 'string';

export const isLegacySubscriptionRaw = (subscriptionRaw: UserSubscriptionRaw): subscriptionRaw is LegacySubscriptionRaw => typeof (subscriptionRaw as LegacySubscriptionRaw).legacySubscriptionID === 'string';

// TODO: NEW – Sync with webapp repository
export const isInTrial = (subscription: UserSubscriptionRaw): boolean => subscription.isTrial;

// TODO: NEW – Sync with webapp repository
export const hasActiveSubscription = (subscriptions: UserSubscriptionRaw[]): boolean => subscriptions.some((subscription) => subscription.status && ['active'].includes(subscription.status));
