import { StatusCodes } from 'http-status-codes';
import ky, { HTTPError, Options, ResponsePromise } from 'ky';
import { ResultAsync } from 'neverthrow';
import AppError from '@/models/AppError';
import { APIErrorCodes, AuthErrorCodes } from '@/enums/ErrorCodes';

export class APIClient {
	#client = ky.extend({
		timeout: 30000,
	});

	constructor(options?: Options) {
		if (!options) {
			return;
		}

		this.setConfig(options);
	}

	setConfig(config: Options): void {
		this.#client = this.#client.extend(config);
	}

	get client(): typeof ky {
		return this.#client;
	}

	async clientWithLoader(...paremeters: Parameters<typeof ky>): Promise<Response> {
		return this.client(...paremeters);
	}

	async clientWithLoaderToJson<T>(...paremeters: Parameters<typeof ky>): Promise<T> {
		return this.clientWithLoader(...paremeters).then((response) => response.json());
	}

	static useWithLoader = (responsePromise: ResponsePromise): Promise<Response> => responsePromise;

	static useWithLoaderToJson = async <T>(responsePromise: ResponsePromise): Promise<T> => responsePromise.then((response) => response.json());
}

export const globalApiReject = (error: unknown): AppError => {
	if (error instanceof AppError) {
		return error;
	}

	if (!(error instanceof HTTPError)) {
		return new AppError(APIErrorCodes.GENERIC);
	}

	if (error.response.status === StatusCodes.UNAUTHORIZED) {
		return new AppError(AuthErrorCodes.LOGIN_UNAUTHORIZED, error);
	}

	return new AppError(APIErrorCodes.GENERIC, error);
};

export const onFetchError = (error: unknown): Error | HTTPError => {
	if (!(error instanceof Error)) {
		return new Error();
	}

	return error;
};

export const toAppError = (error: Error | HTTPError): Promise<AppError> => {
	if (error instanceof AppError) {
		return Promise.resolve(error);
	}

	if (!(error instanceof HTTPError)) {
		return Promise.resolve(new AppError(APIErrorCodes.GENERIC, error));
	}

	return AppError.fromHTTPError(error);
};

export const responseWithErrorHandler = <T>(data: Promise<T>, customErrorHandler?: (error: AppError) => AppError): ResultAsync<T, AppError> => {
	const toCustomError = (error: AppError) => {
		if (!error.isUnauthorized() && customErrorHandler) {
			return customErrorHandler(error);
		}

		return error;
	};

	return ResultAsync.fromPromise(data, onFetchError).mapErr(toAppError).mapErr(toCustomError);
};
