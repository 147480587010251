<template>
	<div>
		<textarea
			v-model="inputValue"
			:disabled="disabled"
			:style="{ height: `${height}px` }"
			:placeholder="placeholder"
			class="font-sans relative rounded-[14px] w-full border-2 border-solid border-gray-200 box-border p-4 transition duration-375 ease-smooth text-sm"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
	modelValue: {
		type: String,
		required: true,
	},
	disabled: {
		type: Boolean,
		required: false,
	},
	height: {
		type: String,
		required: false,
		default: '100',
	},
	placeholder: {
		type: String,
		required: false,
		default: '',
	},
});
const emit = defineEmits(['update:modelValue']);

const inputValue = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	},
});
</script>
