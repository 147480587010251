import { defineStore } from 'pinia';

export const useSubscribeStore = defineStore({
	id: 'subscribe',

	state: () => ({
		currentStepIndex: 0,
		previousStepIndex: 0,
		furthestStepIndex: 0,
		transitionMode: 'transition-next',
	}),

	getters: {
		currentStepCount(state) {
			return state.currentStepIndex + 1;
		},
	},

	actions: {
		setStep(stepNumber: number) {
			const index = stepNumber - 1;

			if (this.furthestStepIndex < index) {
				this.furthestStepIndex = index;
			}

			const newTransitionMode = this.currentStepIndex > index ? 'transition-prev' : 'transition-next';

			this.transitionMode = newTransitionMode;
			this.previousStepIndex = this.currentStepIndex;
			this.currentStepIndex = index;
		},
	},
});
