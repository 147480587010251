export interface PortalCart {
	billwerkPlanVariantId: string;
	billwerkCouponCode?: string;
}

export interface PortalCartOld {
	billwerkPlanVariantID: string;
	billwerkCouponCode?: string;
}

export type PortalCategoryCarts = Record<string, (PortalCart | PortalCartOld)[]>;

export const getPlanVariantId = (portalCart: PortalCart | PortalCartOld): string => {
	if ('billwerkPlanVariantId' in portalCart) {
		return portalCart.billwerkPlanVariantId;
	}

	return portalCart.billwerkPlanVariantID;
};

export const getPlanVariantIds = (categoryCarts: PortalCategoryCarts): string[] => {
	const planVariantsByCategories = Object.entries(categoryCarts)
		.map(([, carts]) => carts.map(getPlanVariantId));

	const planVariantIds = planVariantsByCategories.flat();

	return [...new Set(planVariantIds)];
};
