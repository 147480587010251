<template>
	<transition
		:mode="mode"
		name="expand"
		@enter="enter"
		@after-enter="afterEnter"
		@leave="leave"
	>
		<slot />
	</transition>
</template>

<script setup lang="ts">
/* eslint-disable */
import { PropType } from 'vue';

type TransitionMode = 'default' | 'in-out' | 'out-in' | undefined;

defineProps({
	mode: {
		type: String as PropType<TransitionMode>,
		required: false,
	},
});

const enter = (el: Element) => {
	const element = el as HTMLElement;
	if(!element) {
    	return;
  	}

	const { width, paddingTop } = getComputedStyle(element);

	element.style.width = width;
	element.style.position = 'absolute';
	element.style.visibility = 'hidden';
	element.style.height = 'auto';
	element.style.paddingTop = '0px';

  	const { height } = getComputedStyle(element);

  	element.style.width = '';
  	element.style.position = '';
  	element.style.visibility = '';
  	element.style.height = '0';
  	element.style.paddingTop = '0';

	// Force repaint to make sure the
	// animation is triggered correctly.
	getComputedStyle(element).height;

	// Trigger the animation.
	// We use `setTimeout` because we need
	// to make sure the browser has finished
	// painting after setting the `height`
	// to `0` in the line above.
	setTimeout(() => {
		element.style.height = height;
		element.style.paddingTop = paddingTop;
	});
};

const afterEnter = (el: Element) => {
  const element = el as HTMLElement;
  if(!element) {
    return;
  }

  element.style.height = 'auto';
};

const leave = (el: HTMLElement) => {
  const element = el as HTMLElement;
  if(!element) {
    return;
  }

	const { height, paddingTop } = getComputedStyle(element);

	element.style.height = height;
	element.style.paddingTop = paddingTop;

	// Force repaint to make sure the
	// animation is triggered correctly.
	getComputedStyle(element).height;

	setTimeout(() => {
		element.style.height = '0px';
		element.style.paddingTop = '0px';
	});
};

</script>

<style scoped>
* {
	will-change: height;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
	@apply transition-all duration-375 ease-smooth overflow-hidden;
}

.expand-enter,
.expand-leave-to {
	@apply h-0 opacity-0;
}
</style>
