import { ResultAsync } from 'neverthrow';
import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';
import { updateUserProfile } from '@/api/user';
import { AuthUser } from '@/models/AuthUser';
import { getUserCountryCode } from '@/utilities/UserIPInfo';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import { computed, ref } from 'vue';
import contentApiClient from '@/api/contentApiClient';
import { UserSubscriptionInformationRaw, UserSubscriptionRaw, hasActiveSubscription as hasAnyActiveSubscription } from '@/models/UserSubscriptionRaw';
import { ExperienceLevel, experienceLevels } from '@/models/ExperienceLevel';
import { useAuthStore } from './auth';

export const useUserStore = defineStore('user', () => {
	const qualifiesForTrial = ref(false);
	const billwerkCustomerId = ref<string | null>(null);
	const subscriptions = ref<UserSubscriptionRaw[]>([]);
	const experienceLevel = ref<ExperienceLevel>('beginner');

	const authStore = useAuthStore();

	const addCountryToUserProfile = (user: AuthUser, countryCode: string): ResultAsync<AuthUser, Error> => updateUserProfile(user.userId, { countryCode })
		.andThen(authStore.refreshAccessToken)
		.andThen(authStore.getCurrentUser);

	const maybeAddCountryToUserProfile = async (user: AuthUser): Promise<AuthUser> => {
		if (!isFeatureFlagEnabled(FeatureFlag.COUNTRY_CODE_PROFILE)) {
			return user;
		}

		if (user.country) {
			return user;
		}

		const countryCode = await getUserCountryCode();

		if (!countryCode) {
			return user;
		}

		const profileUpdateResult = await addCountryToUserProfile(user, countryCode);

		if (profileUpdateResult.isErr()) {
			Sentry.captureException(profileUpdateResult.error);
			return user;
		}

		return profileUpdateResult.value;
	};

	const fetchSubscriptions = async () => {
		const response = await contentApiClient.client.get(`user/${authStore.user?.userId}/subscriptions`).json<UserSubscriptionInformationRaw>();

		qualifiesForTrial.value = response.qualifiesForTrial;
		billwerkCustomerId.value = response.billwerkCustomerId;
		subscriptions.value = response.subscriptions;
	};

	const hasActiveSubscription = computed(() => hasAnyActiveSubscription(subscriptions.value));

	const selectedExperienceLevel = computed(() => experienceLevels[experienceLevel.value]);

	return {
		qualifiesForTrial,
		billwerkCustomerId,
		subscriptions,
		hasActiveSubscription,
		experienceLevel,
		selectedExperienceLevel,
		addCountryToUserProfile,
		maybeAddCountryToUserProfile,
		fetchSubscriptions,
	};
});
