<template>
	<div class="desktop:flex">
		<div class="flex-grow mr-6">
			<AppInput
				v-model="inputValue"
				:disabled="success"
				:label="inputLabel"
				:error-message="error"
				:icon="icon"
				class="mb-0"
				@keypress.enter="onSubmit"
			/>
		</div>
		<div>
			<AppButton
				:is-loading="loading"
				:is-success="success"
				color="gray"
				data-cy="button_coupon_redeem"
				@click="onSubmit"
			>
				{{ buttonLabel }}
			</AppButton>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import i18n from '@/plugins/i18n';

export default defineComponent({
	name: 'InputLookup',

	props: {
		modelValue: {
			type: String,
			default: '',
		},
		success: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		buttonLabel: {
			type: String,
			default: i18n.global.t('button.redeemCouponCode'),
		},
		inputLabel: {
			type: String,
			default: i18n.global.t('input.yourVoucherCode'),
		},
		error: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: 'barcode-alt',
		},
	},

	emits: ['update:modelValue', 'submit'],

	computed: {
		inputValue: {
			get() {
				return this.modelValue;
			},
			set(value: string) {
				this.$emit('update:modelValue', value);
			},
		},
	},

	methods: {
		onSubmit() {
			this.inputValue = this.inputValue.trim();
			this.$emit('submit');
		},
	},
});
</script>
