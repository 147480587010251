import { ResultAsync } from 'neverthrow';
import { CouponLookupResponse } from '@/models/CouponLookupResponse';
import { responseWithErrorHandler } from '.';
import contentApiClient from './contentApiClient';

export const lookupCouponCode = (couponCode: string): ResultAsync<CouponLookupResponse, Error> => {
	const data = contentApiClient.client
		.get(`Coupon/${couponCode}`)
		.json<CouponLookupResponse>();

	return responseWithErrorHandler(data);
};
