<template>
	<div>
		<LoadingBar
			:progress="appProgressLoader"
			class="absolute left-0 top-0 w-full z-[9999]"
			bar-classes="w-full transition-all ease-linear pointer-events-none transform-gpu scale-x-0 origin-left bg-primary-400 shadow-glow"
		/>
		<router-view
			v-slot="{ Component }"
		>
			<transition
				name="fade"
				mode="out-in"
			>
				<component :is="layout">
					<component :is="Component" />
				</component>
			</transition>
		</router-view>
		<CustomErrorModal />
	</div>
</template>

<script lang="ts" setup>
import { useAppProgressLoader, LoadingBar } from '@sunlabde/vuelab';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import {
	computed, onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CustomErrorModal from '@/components/CustomErrorModal.vue';
import errorHandler from './utilities/ErrorHandler';
import { useIntercom } from './plugins/intercom/useIntercom';
import { useAuthStore, useAuthStoreWatcher } from './store/auth';
import { trackBranchName } from './utilities/tracking';
import { useMainStore } from './store/main';

const router = useRouter();
const route = useRoute();
const appProgressLoader = useAppProgressLoader();
const intercom = useIntercom();

trackBranchName();

onMounted(() => {
	const authStore = useAuthStore();
	const { onUserLogout, onUserLogin } = useAuthStoreWatcher();

	authStore.getAuthUser()
		.map((user) => {
			intercom.client.boot({
				user_id: user.profileId,
				user_hash: user.intercomHash,
				name: `${user.firstname} ${user.lastname}`,
				email: user.email,
				beta: true,
			});
			return user;
		})
		.mapErr(() => {
			intercom.client.boot();
		});

	onUserLogin((user) => {
		Sentry.setUser(user);
		authStore.onAuthenticationError = (error) => {
			authStore.onAuthenticationError = null;
			if (route.name === 'signup') {
				return;
			}
			router.push({ name: 'signup', query: { redirectTo: route.path } });
			errorHandler.report(error);
		};
	});

	onUserLogout(() => {
		intercom.client.shutdown();
		intercom.client.boot();
		Sentry.getCurrentScope().setUser(null);
	});

	axios.interceptors.response.use(undefined, (error) => new Promise(() => {
		if (error.response.status === 401 && error.response.config && !error.response.config.__isRetryRequest) {
			authStore.removeAuthentication();
		}
		throw error;
	}));
});

const mainStore = useMainStore();
const layout = computed(() => mainStore.viewLayoutComponent);
</script>
