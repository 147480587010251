import { ResultAsync } from 'neverthrow';
import * as Sentry from '@sentry/vue';

interface AppVersion {
	version: string;
}

const APP_VERSION: string = __COMMIT_REF__ || 'undefined';

const getCurrentAppVersion = (): ResultAsync<AppVersion, Error> => {
	const data: Promise<AppVersion> = fetch('/version.json').then((response) => response.json());

	return ResultAsync.fromPromise(data, () => new Error('could not get current app version'));
};

export const isAppVersionUpToDate = async (): Promise<boolean> => {
	const currentAppVersion = await getCurrentAppVersion().unwrapOr({ version: 'undefined' });

	if (currentAppVersion.version === 'undefined') {
		Sentry.captureMessage('isAppVersionUpToDate was undefined');
		return true;
	}

	return currentAppVersion.version === APP_VERSION;
};

export const ensureAppVersionOrReload = async (location: string): Promise<void> => {
	const isAppUpToDate = await isAppVersionUpToDate();

	if (!isAppUpToDate) {
		window.location.replace(location);
	}
};
