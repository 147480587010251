import { defineRule, configure } from 'vee-validate';
import {
	required, email, min, alpha, confirmed, alpha_spaces as alphaSpaces, digits,
} from '@vee-validate/rules';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import { localize } from '@vee-validate/i18n';

const isIBAN = (value: string): boolean => {
	const iban = electronicFormatIBAN(value);

	if (iban === null) {
		return false;
	}
	return isValidIBAN(iban);
};

const isCountryValid = (value: string, { country }: { country: string }) => {
	const countryCode = country ? country.toUpperCase() : '';

	if (postcodeValidatorExistsForCountry(countryCode)) {
		return postcodeValidator(value, countryCode);
	}

	return true;
};

// Install rules
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('alpha', alpha);
defineRule('confirmed', confirmed);
defineRule('alpha_spaces', alphaSpaces);
defineRule('digits', digits);
defineRule('iban', isIBAN);
defineRule('postcode', isCountryValid);

configure({
	validateOnBlur: true,
	generateMessage: localize({
		de,
		en,
		es,
	}),
});
