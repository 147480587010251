import { DateTime } from 'luxon';

export interface DiscountTargets {
	[planVariantId: string]: boolean;
}

export interface Discount {
	Id: string;
	Effect: {
		ReductionPercent: number;
	},
	Duration: {
		Quantity: number;
		Unit: string;
	},
	Targets: DiscountTargets;
}

export type DiscountWithCode = Discount & { code: string };

export const getEndDate = (discount: Discount, startDate = new Date()): Date => DateTime.fromJSDate(startDate)
	.plus({ [discount.Duration.Unit]: discount.Duration.Quantity })
	.toJSDate();
