import { ResultAsync } from 'neverthrow';
import { PlanVariant } from '@/models/PlanVariant';
import { responseWithErrorHandler } from '.';
import contentApiClient from './contentApiClient';

export const getPlanVariantById = (planVariantId: string): ResultAsync<PlanVariant, Error> => {
	const data: Promise<PlanVariant> = contentApiClient.client.get(`planvariants/${planVariantId}`, {
		headers: {
			'api-version': '3.1',
		},
	}).json();

	return responseWithErrorHandler(data);
};

export const getPlanVariantsById = (planVariantIds: string[]): ResultAsync<PlanVariant[], Error> => {
	const data: Promise<PlanVariant[]> = contentApiClient.client.post('planvariants', {
		headers: {
			'api-version': '3.1',
		},
		json: planVariantIds,
	}).json();

	return responseWithErrorHandler(data);
};
