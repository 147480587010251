<template>
	<div>
		{{ $t('subscribe.step.plan.paypal.title') }}
	</div>
</template>

<script lang="ts" setup>
import { onActivated } from 'vue';

const emit = defineEmits(['change', 'success', 'update:secretPaymentData', 'update:customerChange']);

/**
 * Because this Component is most likely be used in a <keep-alive> environment it's
 * important that we also make use of the activated lifecycle to emit to current validation state
 * https://vuejs.org/api/options-lifecycle.html#activated
 */
onActivated(() => {
	emit('change', true);
});

const submit = () => {
	emit('update:secretPaymentData', {
		bearer: 'PayPal',
	});

	emit('success');
};

defineExpose({ submit });
</script>
