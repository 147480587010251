import { inject } from 'vue';
import { AuthUser } from '@/models/AuthUser';
import { INTERCOM_KEY } from '.';

export const useIntercom = () => {
	const intercom = inject(INTERCOM_KEY);

	if (!intercom) {
		throw new Error('Intercom plugin has not been provided.');
	}

	const bootUser = (user: AuthUser) => intercom.boot({
		user_id: user.profileId,
		user_hash: user.intercomHash,
		name: `${user.firstname} ${user.lastname}`,
		email: user.email,
		beta: true,
	});

	const bootGuest = () => intercom.boot();

	return {
		client: intercom,
		bootUser,
		bootGuest,
	};
};
