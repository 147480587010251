<template>
	<div class="container min-h-full flex items-center px-6 desktop:max-w-[1072px]">
		<div class="w-full mb-8 mx-auto phablet:w-8/12 widescreen:w-6/12">
			<div class="mb-8">
				<h2
					data-cy="view_signup_title"
					class="text-3xl font-bold mb-2"
				>
					{{ $t(`view.signup.title.${signupStyle}`) }}
				</h2>
				<p>
					{{ $t('signup.subtitle') }}
					<RouterLink
						:to="{ name: 'login', query: { redirectTo: $route.query.redirectTo } }"
						class="text-primary-400 no-underline hover:cursor-pointer hover:underline"
						data-cy="link_ToLogin"
					>
						{{ $t('button.loginNow') }}
					</RouterLink>
				</p>
			</div>
			<div>
				<form
					ref="observer"
					data-cy="form"
					@submit.prevent="onFormSubmit"
				>
					<div class="grid gap-4">
						<AppInput
							v-model="firstname"
							:label="$t('input.firstname')"
							data-cy="form_firstname"
							icon="user"
							type="text"
							:error-message="firstnameErrors[0]"
							:is-valid="firstnameMeta.valid"
							auto-focus
							@blur="firstnameBlurHandler"
						/>
						<AppInput
							v-model="lastname"
							:label="$t('input.lastname')"
							data-cy="form_lastname"
							icon="user"
							type="text"
							:error-message="lastnameErrors[0]"
							:is-valid="lastnameMeta.valid"
							@blur="lastnameBlurHandler"
						/>
						<AppInput
							v-model="email"
							:label="$t('input.yourEmail')"
							data-cy="form_emailAdress"
							icon="envelope"
							type="email"
							:error-message="emailErrors[0]"
							:is-valid="emailMeta.valid"
							v-on="emailValidationListeners"
						/>
						<AppInput
							v-model="yourPassword"
							:label="$t('input.yourPassword')"
							data-cy="form_yourPassword"
							icon="lock-alt"
							type="password"
							:error-message="yourPasswordErrors[0]"
							:is-valid="yourPasswordMeta.valid"
							v-on="yourPasswordValidationListeners"
						/>
						<AppInput
							v-model="passwordConfirm"
							:label="$t('input.confirmYourPassword')"
							data-cy="form_passwordConfirm"
							icon="lock-alt"
							type="password"
							:error-message="passwordConfirmErrors[0]"
							:is-valid="passwordConfirmMeta.valid"
							v-on="passwordConfirmValidationListeners"
						/>
					</div>
					<i18n-t
						keypath="view.signup.legalNotes.text"
						tag="p"
						scope="global"
						class="text-xs my-6 text-gray-700"
					>
						<template #actionTos>
							<a
								:href="staticLinkComposer.compose('termsOfUse')"
								class="text-primary-400 no-underline hover:cursor-pointer hover:underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								{{ $t('view.signup.legalNotes.actionTOS') }}
							</a>
						</template>
						<template #actionPrivacyPolicy>
							<a
								:href="staticLinkComposer.compose('dataPrivacy')"
								class="text-primary-400 no-underline hover:cursor-pointer hover:underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								{{ $t('view.signup.legalNotes.actionPrivacyPolicy') }}
							</a>
						</template>
					</i18n-t>
					<AppButton
						:is-disabled="!formMeta.valid"
						:is-loading="waitingForUserCreation"
						data-cy="form_submitButton"
						class="w-full"
						type="submit"
					>
						{{ $t('button.signup.confirm') }}
					</AppButton>
				</form>
			</div>
			<div class="mt-8">
				<AppInfobox :image="VoucherSampleImage">
					<template #title>
						{{ $t('signup.couponBox.title') }}
					</template>
					<template #text>
						{{ $t('signup.couponBox.body') }}
					</template>
				</AppInfobox>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useField, useForm } from 'vee-validate';
import * as Sentry from '@sentry/vue';
import errorHandler from '@/utilities/ErrorHandler';
import { FormError } from '@/utilities/ErrorClasses';
import { useAuthStore } from '@/store/auth';
import { getUserCountryCode } from '@/utilities/UserIPInfo';
import { useStaticLinkComposer } from '@/utilities/StaticLinks';
import { useIntercom } from '@/plugins/intercom/useIntercom';
import { useFieldLazy } from '@/composables/useFieldLazy';
import VoucherSampleImage from '@/assets/voucher-sample.png';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/store/main';
import { NewUserData } from '@/models/NewUserData';
import { useGoogleRecaptcha } from '@/plugins/GoogleRecaptcha/useGoogleRecaptcha';
import { useToast } from '@sunlabde/m2m-library';
import { useSignupTracking } from '@/composables/useSignupTracking';

interface SignupForm {
	firstname: string;
	lastname: string;
	email: string;
	yourPassword: string;
	passwordConfirm: string;
}

const staticLinkComposer = useStaticLinkComposer();

const router = useRouter();
const route = useRoute();

const { t, locale } = useI18n();
const intercom = useIntercom();

const { signupStyle } = storeToRefs(useMainStore());

const { meta: formMeta, validate: validateForm, setTouched } = useForm<SignupForm>();

const { getUserTokenForAction } = useGoogleRecaptcha();

onMounted(() => {
	/**
	 * We need to add an padding of 90px to intercom to avoid overlapping
	 * with the Google ReCAPTCHA v3 batch
	 */
	intercom.client.updateDefaults({ horizontal_padding: 90 });
});

const {
	value: firstname,
	errors: firstnameErrors,
	meta: firstnameMeta,
	handleBlur: firstnameBlurHandler,
} = useField<string>(
	'firstname',
	{ required: true },
	{ label: t('input.firstname'), initialValue: '' },
);

const {
	value: lastname,
	errors: lastnameErrors,
	meta: lastnameMeta,
	handleBlur: lastnameBlurHandler,
} = useField<string>(
	'lastname',
	{ required: true },
	{ label: t('input.lastname'), initialValue: '' },
);

const {
	value: email,
	errors: emailErrors,
	meta: emailMeta,
	validationListeners: emailValidationListeners,
} = useFieldLazy<string>(
	'email',
	{ required: true, email: true },
	{
		label: t('input.yourEmail'),
		initialValue: '',
	},
);

const {
	value: yourPassword,
	errors: yourPasswordErrors,
	meta: yourPasswordMeta,
	validationListeners: yourPasswordValidationListeners,
} = useFieldLazy<string>(
	'yourPassword',
	{ required: true, min: 6 },
	{ label: t('input.yourPassword'), initialValue: '' },
);

const {
	value: passwordConfirm,
	errors: passwordConfirmErrors,
	meta: passwordConfirmMeta,
	validationListeners: passwordConfirmValidationListeners,
} = useFieldLazy<string>(
	'passwordConfirm',
	{ required: true, confirmed: '@yourPassword' },
	{ label: t('input.confirmYourPassword'), initialValue: '' },
);

const waitingForUserCreation = ref<boolean>(false);

const { trackNewSignup } = useSignupTracking();

const toast = useToast();

const onFormSubmit = async () => {
	const authStore = useAuthStore();

	try {
		waitingForUserCreation.value = true;

		const validationResponse = await validateForm();

		if (!validationResponse.valid) {
			waitingForUserCreation.value = false;
			setTouched({
				firstname: true,
				lastname: true,
				email: true,
				yourPassword: true,
				passwordConfirm: true,
			});

			throw new FormError('inputsMissing');
		}

		const token = await getUserTokenForAction('signup');

		const user: NewUserData = {
			firstname: firstname.value,
			lastname: lastname.value,
			email: email.value,
			password: yourPassword.value,
			languageISO6391Representation: locale.value,
			urlPrefix: `${import.meta.env.VITE_APP_URL}/${locale.value}/activateAccount/`,
			token,
		};

		const countryCode = await getUserCountryCode();

		if (countryCode) {
			user.countryCode = countryCode;
		}

		const signupFlow = await authStore.createUser(user)
			.andThen(() => authStore.loginUser(user.email, user.password));

		const authUser = authStore.user;

		if (signupFlow.isErr() || !authUser) {
			const error = signupFlow.isErr() ? signupFlow.error : new Error('No Auth User');

			const eventId = Sentry.captureException(error, (scope) => {
				scope.setLevel('error');
				scope.setTransactionName('Signup failed because of an unexpected error.');
				return scope;
			});

			toast.error(t('notification.error.signup.withErrorCode.text', [eventId]), { timeout: false });

			errorHandler.report(error);

			return;
		}

		intercom.client.update({
			user_id: authUser.profileId,
			user_hash: authUser.intercomHash,
			name: `${authUser.firstname} ${authUser.lastname}`,
			email: authUser.email,
			beta: true,
		});

		trackNewSignup();

		if (signupStyle.value === 'thomann') {
			router.push({
				name: 'subscribe',
				query: {
					type: 'coupon',
					step: 2,
				},
			});
		} else {
			router.push(route.query.redirectTo as string || { name: 'subscribe' });
		}
		// eslint-disable-next-line
	} catch (error: any) {
		errorHandler.report(error);
	} finally {
		waitingForUserCreation.value = false;
	}
};
</script>
