import currency from 'currency.js';

export type ISOCurrency = 'EUR' | 'USD';

export const currencies: Record<ISOCurrency, (value: number, options?: currency.Options) => currency> = {
	EUR: (value, options) => currency(value, {
		...options,
		separator: ' ',
		decimal: ',',
		symbol: '€',
		pattern: '# !',
	}),
	USD: (value, options) => currency(value, options),
};

export const getCurrencyObject = (value: number, targetCurrency: ISOCurrency, options?: currency.Options): currency => (currencies[targetCurrency])(value, options);

export const getFormattedPrice = (value: number, targetCurrency: ISOCurrency, options?: currency.Options): string => getCurrencyObject(value, targetCurrency, options).format();

export const getFormattedPriceWithMaybeCents = (value: number, targetCurrency: ISOCurrency, options?: currency.Options): string => {
	const currencyObject = getCurrencyObject(value, targetCurrency, options);

	if (currencyObject.cents()) {
		return currencyObject.format();
	}

	return getFormattedPrice(currencyObject.dollars(), targetCurrency, { ...options, precision: 0 });
};
