import { useGtm } from '@gtm-support/vue-gtm';
import SubscriptionOrder from '@/utilities/SubscriptionOrder';
import { BillingOccurence } from '@/models/BillingOccurence';
import { PartialRecord } from '@/models/PartialRecord';
import logger from '@/plugins/Logger';
import * as Sentry from '@sentry/vue';
import { PlanCategory } from '@/models/PlanCategory';
import { plausible } from '@/plugins/plausible/setup';
import i18n from '@/plugins/i18n';

type TrackingProvider = 'plausible';

const billingOccurenceGoals: Record<string, PartialRecord<BillingOccurence, Record<TrackingProvider, string>>> = {
	piano: {
		Monthly: {
			plausible: 'Piano-1M',
		},
		Biannually: {
			plausible: 'Piano-6M',
		},
		Yearly: {
			plausible: 'Piano-12M',
		},
	},
	guitar: {
		Monthly: {
			plausible: 'Guitar-1M',
		},
		Biannually: {
			plausible: 'Guitar-6M',
		},
		Yearly: {
			plausible: 'Guitar-12M',
		},
	},
	drums: {
		Monthly: {
			plausible: 'Drums-1M',
		},
		Biannually: {
			plausible: 'Drums-6M',
		},
		Yearly: {
			plausible: 'Drums-12M',
		},
	},
};

export const getPlausibleGoal = (order: SubscriptionOrder): string => {
	const plan = order.getPlan();

	if (!plan.CustomFields) {
		return '';
	}

	const categoryIdMap: Record<PlanCategory, string> = {
		piano: billingOccurenceGoals.piano[order.billingOccurence.id]?.plausible || 'Subscription Piano',
		guitar: billingOccurenceGoals.guitar[order.billingOccurence.id]?.plausible || 'Subscription Guitar',
		drums: billingOccurenceGoals.drums[order.billingOccurence.id]?.plausible || 'Subscription Drums',
		combo: 'Subscription Combo',
	};

	const planCategory: PlanCategory = plan.CustomFields.category;

	return categoryIdMap[planCategory];
};

export const trackOrder = (order: SubscriptionOrder) => {
	try {
		const gtm = useGtm();

		if (gtm) {
			gtm.trackEvent({
				transactionId: order.getID(),
				transactionAffiliation: 'music2me',
				transactionTotal: order.getTotal(),
				transactionTax: order.getTaxes(),
				transactionValue: order.getValue(),
				transactionProducts: [{
					sku: order.getPlanID(),
					name: order.getLocalizedDisplayName(i18n.global.locale),
					category: order.getCategory(),
					price: order.getTotal(),
					quantity: 1,
				}],
				event: 'transactionComplete',
			});
		} else {
			Sentry.captureMessage('[trackOrder] gtm not found', 'error');
		}

		plausible.trackEvent(getPlausibleGoal(order), { props: { totalInCents: order.totalInCents } });

		logger.info('Successfully tracked order', order);
	} catch (error) {
		Sentry.captureException(error);
		logger.info('Failed to track order', order, error);
	}
};

export const trackBranchName = () => {
	const gtm = useGtm();

	if (!gtm) {
		return;
	}

	gtm.trackEvent({
		ab_branch: __BRANCH_NAME__,
		event: 'ab_branch',
	});

	Sentry.setTag('branch', __BRANCH_NAME__);

	logger.info('Successfully tracked branch name', __BRANCH_NAME__);
};
