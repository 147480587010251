import * as Sentry from '@sentry/vue';
import { StatusCodes } from 'http-status-codes';
import { AfterResponseHook, BeforeRequestHook } from 'ky';
import { ResultAsync, Result } from 'neverthrow';
import AppError from '@/models/AppError';
import { AuthErrorCodes } from '@/enums/ErrorCodes';
import logger from '@/plugins/Logger';
import { APIClient } from '@/api';

type GetTokenFunction = () => ResultAsync<string, AppError> | Result<string, AppError>;

export interface SetupHooksOptions {
	client: APIClient,
	getAccessToken: GetTokenFunction,
	refreshAccessToken: GetTokenFunction
	onUnauthorized: (error: AppError) => void,
}

export const getAuthorizationRetryCount = (request: Request): number => {
	const authorizationRetryCountHeader = request.headers.get('X-Authorization-Retry-Count');

	if (!authorizationRetryCountHeader) {
		return 0;
	}

	return parseInt(authorizationRetryCountHeader, 10);
};

export const useApiAuthHooks = () => {
	const setupAuthenticationHooks = (options: SetupHooksOptions) => {
		const beforeRequest: BeforeRequestHook = async (request) => {
			const accessToken = await options.getAccessToken();

			if (accessToken.isErr()) {
				logger.log('Made an request without access token');

				Sentry.addBreadcrumb({
					category: 'Auth',
					message: 'Made an request without access token',
					level: 'info',
				});
			}

			if (accessToken.isOk()) {
				request.headers.set('Authorization', `Bearer ${accessToken.value}`);
			}
		};

		const afterResponse: AfterResponseHook = async (request, afterResponseOptions, response) => {
			const authorizationRetryCount = getAuthorizationRetryCount(request);

			if (response.status === StatusCodes.UNAUTHORIZED) {
				if (authorizationRetryCount > 1) {
					Sentry.addBreadcrumb({
						category: 'Auth',
						message: 'Made an request and received UNAUTHORIZED multiple times',
						level: 'error',
					});

					options.onUnauthorized(new AppError(AuthErrorCodes.TOKEN_UNAUTHORIZED));
					return response;
				}

				Sentry.addBreadcrumb({
					category: 'Auth',
					message: 'Made an request and received UNAUTHORIZED',
					level: 'info',
				});

				const refreshResult = await options.refreshAccessToken();

				if (refreshResult.isErr() && refreshResult.error.message !== AuthErrorCodes.REFRESH_TOKEN_MISSING) {
					Sentry.withScope((scope) => {
						scope.setLevel('info');
						scope.setTransactionName('refreshAccessToken failed');
						Sentry.captureException(refreshResult.error);
					});
				}

				if (refreshResult.isOk()) {
					request.headers.set('Authorization', `Bearer ${refreshResult.value}`);

					return options.client.client(request, {
						headers: {
							'X-Authorization-Retry-Count': `${authorizationRetryCount + 1}`,
						},
					});
				}

				options.onUnauthorized(new AppError(AuthErrorCodes.ACCESS_TOKEN_INVALID));
			}

			return response;
		};

		options.client.setConfig({
			hooks: {
				beforeRequest: [beforeRequest],
				afterResponse: [afterResponse],
			},
		});
	};

	return {
		setupAuthenticationHooks,
	};
};
