import * as Sentry from '@sentry/vue';
import { BeforeRequestHook } from 'ky';

export const useCustomSentryTransaction = () => {
	const transactionID = Math.random().toString(36).substr(2, 9);

	Sentry.getCurrentScope().setTag('transaction_id', transactionID);

	return {
		transactionID,
	};
};

export const sentryTransactionHook: BeforeRequestHook = (request) => {
	const { transactionID } = useCustomSentryTransaction();
	request.headers.set('X-Transaction-ID', transactionID);
};
