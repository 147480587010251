import { App } from 'vue';
import { FontAwesomeIcon } from '@sunlabde/m2m-library';

export const registerGlobalComponents = (app: App) => {
	const requireComponent = import.meta.glob<any>('@/components/App*.vue', { eager: true });

	Object.entries(requireComponent).forEach(([path, definition]) => {
		// Get name of component, based on filename
		// "./components/Fruits.vue" will become "Fruits"
		const componentName = path.split('/').pop()?.replace(/\.\w+$/, '');

		if (!componentName) {
			return;
		}

		// Register component on this Vue instance
		app.component(componentName, definition.default);
	});

	app.component('FontAwesomeIcon', FontAwesomeIcon);
};
