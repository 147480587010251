<template>
	<CardPlanVariant
		:plan-variant="planVariant"
		:discount="discount"
		:portal-category-products="portalCategoryProducts"
		:combo-comparison-by-billing-occurence="comboComparisonByBillingOccurence"
		:allows-trial-order="allowsTrialOrder"
		@click="onClick"
	/>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { PlanVariant, PriceComparison } from '@/models/PlanVariant';
import { Discount } from '@/models/Discounts';
import { storeToRefs } from 'pinia';
import { useProductsStore } from '@/store/products';
import { CardPlanVariant } from '@sunlabde/m2m-library';
import { Product } from '@/models/Product';
import { BillingOccurence } from '@/models/BillingOccurence';

const props = defineProps({
	planVariant: {
		type: Object as PropType<PlanVariant>,
		required: true,
	},
	discount: {
		type: Object as PropType<Discount | null>,
		default: null,
	},
});

const emit = defineEmits(['click']);

const onClick = (event: MouseEvent) => {
	emit('click', event);
};

const productsStore = useProductsStore();
const { allowsTrialOrder } = storeToRefs(productsStore);

const portalCategoryProducts = computed((): Product[] => productsStore.getProductsFromCategory(props.planVariant.CustomFields.category));
const comboComparisonByBillingOccurence = computed((): Record<BillingOccurence, PriceComparison> => productsStore.comboComparisonByBillingOccurence);
</script>
