import { App, InjectionKey } from 'vue';
import Plausible from 'plausible-tracker';
import { PlausibleInitOptions } from 'plausible-tracker/build/main/lib/tracker';

export const PLAUSIBLE_PLUGIN_KEY: InjectionKey<ReturnType<typeof Plausible>> = Symbol('Plausible');

export const createPlausible = (options: PlausibleInitOptions) => {
	const plausible = Plausible(options);

	plausible.enableAutoPageviews();

	const install = (app: App) => {
		app.provide(PLAUSIBLE_PLUGIN_KEY, plausible);
	};

	return {
		...plausible,
		install,
	};
};
